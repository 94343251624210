import { DateTime } from 'luxon';

import { ActionContext } from 'vuex';
import { AddToCartRes, Coupon, FullOrder, HistoryOrder, LineItem, OrderLogistics, OrderTotals } from '@/models/cartModels';
import { addToCart, deleteFromCart, updateCartItem, orderPrice, submitOrder, buildOrderConfirmation, replaceCart, addOrderToCart } from '@/services/orderService';
import { deleteLocalStorage, getLocalStorage, localStorageEnum, replaceLocalStorage, setLocalStorage } from '@/services/localStorageService';
import { Payment } from '@/models/PaymentModels';
import { PaymentIdEnum } from '@/enums/PaymentTypeEnum';
import { errorToast, successToastDetail } from '@/services/pageService';
import selectedLocationModule from '@/store/selectedLocationModule';
import { stringToBool } from '@/services/stringService';
import { useGoogleAnalytics } from '../composables/useGoogleAnalytics';
import { PageState } from '@/store/pageModule';
import { AccountState } from '@/store/accountModule';

export interface OrderState {
    deliveryCharge?: number;
    cartItems: LineItem[];
    estimatedTime?: DateTime;
    validCoupons: Coupon[];
    payments: any[];
    tip: number;
    subTotal?: number;
    taxTotal: number;
    grandTotal?: number;
    submittedOrder?: FullOrder;
    orderProcessing: boolean;
    orderError?: string;
    latestMessage: string;
    stellarInfo: any;
    rewardsData: any;
    rewardModal: boolean;
    isLoader: boolean;
    isEditCartLoader: boolean;
    skipPriceAlignment: boolean;
}

const OrderState: OrderState = {
    tip: 0,
    taxTotal: 0,
    cartItems: [],
    validCoupons: getLocalStorage(localStorageEnum.coupons) ?? [],
    payments: [],
    orderProcessing: false,
    latestMessage: '',
    stellarInfo: null,
    rewardsData: [],
    rewardModal: false,
    isLoader: false,
    isEditCartLoader: false,
    skipPriceAlignment: false,
};

export default {
    state: OrderState,
    mutations: {
        SET_SKIP_PRICE_ALIGNMENT: (state: OrderState, val: boolean) => {
            state.skipPriceAlignment = val;
        },
        ADD_CART_ITEM: (state: OrderState, item: LineItem): void => {
            state.cartItems.push(item);
            replaceLocalStorage(localStorageEnum.cart, { lineItems: state.cartItems }, 1);
        },
        REMOVE_CART_ITEM: (state: OrderState, lineItem: LineItem): void => {
            const removeIndex = state.cartItems.findIndex((cart) => cart == lineItem);
            if (removeIndex > -1) {
                state.cartItems.splice(removeIndex, 1);
                replaceLocalStorage(localStorageEnum.cart, { lineItems: state.cartItems }, 1);
            }
        },
        UPDATE_CART_ITEM: (state: OrderState, item: LineItem): void => {
            const removeIndex = state.cartItems.findIndex((cart) => +(cart.lineitemId ?? 0) == +(item.lineitemId ?? 0));
            if (removeIndex || removeIndex == 0) state.cartItems[removeIndex] = item;
            replaceLocalStorage(localStorageEnum.cart, { lineItems: state.cartItems }, 1);
        },
        CLEAR_CART: (state: OrderState): void => {
            state.cartItems = [];
            deleteLocalStorage(localStorageEnum.cart);
        },
        LOGOUT_CART: (state: OrderState): void => {
            state.cartItems = [];
            // logout will now start a fresh session instead of holding onto any cart data
            deleteLocalStorage(localStorageEnum.cart);
            deleteLocalStorage(localStorageEnum.orderLogistics);

            // Old way - replace cart with no user attached
            // state.cartItems.forEach((i) => (i.customerId = ""));
            // replaceLocalStorage(localStorageEnum.cart, { lineItems: state.cartItems }, 1);
        },

        UPDATE_ESTIMATED_TIME: (state: OrderState, date: string) => {
            state.estimatedTime = DateTime.fromSQL(date);
        },
        ADD_COUPON: (state: OrderState, coupon: Coupon) => {
            state.validCoupons.push(coupon);
        },
        REMOVE_COUPON: (state: OrderState, coupon: Coupon) => {
            const { trackRemoveCoupon } = useGoogleAnalytics();
            const removeIndex = state.validCoupons.findIndex((valid) => valid.name === coupon.name);
            if (removeIndex > -1) {
                trackRemoveCoupon(coupon.code);
                state.validCoupons.splice(removeIndex, 1);
            }
        },
        REPLACE_ALL_COUPONS: (state: OrderState, coupons: Coupon[]) => {
            const newCoupons: Coupon[] = [];
            coupons.forEach((apiCoupon) => {
                const oldCoupon = state.validCoupons.find((localCoupon) => localCoupon.phoenixCode === apiCoupon.phoenixCode);
                if (oldCoupon) {
                    // remove coupon from state so it can't get processed twice
                    // single use coupons use the same phoenixCode, this ensures we move on
                    // to the next available singleUseCoupon instead of duplicating the first one found
                    const index = state.validCoupons.indexOf(oldCoupon);
                    if (index > -1) state.validCoupons.splice(index, 1);
                    // Keep .campaign, .campaignCodeId, and .code from user entered coupon
                    // .code gets replaced with phoenixCode by the api but singleUseCodes also uses .code field
                    apiCoupon.campaign = oldCoupon.campaign;
                    apiCoupon.campaignCodeId = oldCoupon.campaignCodeId;
                    apiCoupon.code = oldCoupon.code;
                    newCoupons.push(apiCoupon);
                } else {
                    newCoupons.push(apiCoupon);
                }
            });
            state.validCoupons = newCoupons;
        },
        RESET_COUPONS: (state: OrderState) => {
            state.validCoupons = [];
        },
        UPDATE_TIP: (state: OrderState, tip: number) => (state.tip = tip),
        UPDATE_PRICES: (state: OrderState, fullOrder: FullOrder): void => {
            state.subTotal = +(fullOrder?.orderDetails?.subTotal ?? 0);
            state.taxTotal = +(fullOrder?.orderDetails?.taxTotal ?? 0);
            state.grandTotal = +(fullOrder?.orderDetails?.grandTotal ?? 0);

            // Delivery Charge lives with the selected location not on the order.
            const isDelivery = fullOrder.orderDetails.orderLogistics.orderType?.toLocaleUpperCase() == 'DELIVERY';
            const deliveryCharge: number = isDelivery ? +(selectedLocationModule.state.location?.deliveryCharge ?? 0) : 0.0;
            state.deliveryCharge = deliveryCharge;
        },
        UPDATE_ITEM_PRICES: (state: OrderState, fullOrder: FullOrder): void => {
            const orderItems: any[] = [Object.values(fullOrder.orderDetails.lineItems)].flat();
            const cartItems = state.cartItems;
            // originally based on item.id but miltiple items can have the same id with different customizations
            // this was causing all similar items to have the same price even if they had extra/less toppings
            if (orderItems && cartItems) {
                cartItems.forEach((item, index1) => {
                    orderItems.forEach((orderItem, index2) => {
                        if (index1 == index2) {
                            item.price = orderItem.price;
                        }
                    });
                });
            }
            replaceLocalStorage(localStorageEnum.cart, { lineItems: state.cartItems }, 1);
        },
        ADD_ORDER_PAYMENT: (state: OrderState, payment: Payment) => {
            state.payments.push(payment);
        },
        REMOVE_ORDER_PAYMENT: (state: OrderState, payment: Payment) => {
            const removeIndex = state.payments.findIndex((p) => p.paymentType == payment.paymentType);
            state.payments.splice(removeIndex, 1);
        },
        REMOVE_ORDER_PAYMENT_TYPE: (state: OrderState, paymentType: string) => {
            const removeIndex = state.payments.findIndex((p) => p.paymentType == paymentType);
            removeIndex >= 0 && state.payments.splice(removeIndex, 1);

            // always remove secondary payment
            state.payments.length > 1 && state.payments.splice(1, 1);
        },
        UPDATE_ORDER_PAYMENT_AMOUNT: (state: OrderState, total: number) => {
            if (state.payments[0] && state.payments[0].isGiftCard) {
                const giftCard = state.payments[0];
                giftCard.amount = +total >= +giftCard.balance ? giftCard.balance : total;
                if (state.payments[1]) {
                    const balanceDue = total - giftCard.amount;
                    state.payments[1].amount = `${balanceDue}`;
                    // remove extra payment if no longer needed
                    balanceDue <= 0 && state.payments.splice(1, 1);
                }
            } else if (state.payments[0]) {
                state.payments[0].amount = total;
            }
        },
        CLEAR_ORDER_PAYMENTS: (state: OrderState) => (state.payments = []),
        SET_ORDER_PROCESSING: (state: OrderState, processing: boolean) => (state.orderProcessing = processing),
        SET_ORDER_ERROR: (state: OrderState, error: string) => (state.orderError = error),
        SUBMITTED_ORDER: (state: OrderState, order: FullOrder) => (state.submittedOrder = new FullOrder(order)),
        UPDATE_LATEST_MESSAGE: (state: OrderState, message: string) => (state.latestMessage = message),
        SET_STELLAR_INFO: (state: OrderState, item: any): void => {
            state.stellarInfo = item;
        },
        SET_REWARDS_DATA: (state: OrderState, item: any) => (state.rewardsData = item),
        SET_REWARDS_MODAL: (state: OrderState, val: boolean) => (state.rewardModal = val),
        SET_FLAVOR_LOADER: (state: OrderState, val: boolean) => (state.isLoader = val),
        SET_CARTEDIT_LOADER: (state: OrderState, val: boolean) => (state.isEditCartLoader = val),
    },
    actions: {
        setSkipPriceAlignment(context: ActionContext<AccountState, any>, val: boolean) {
            context.commit('SET_SKIP_PRICE_ALIGNMENT', val);
        },
        async initCart(context: ActionContext<OrderState, any>, lineItems: LineItem[]): Promise<void> {
            const accountId = context.getters.getAccountId;

            if (lineItems.length > 0 && context.getters.getCartItems?.length == 0) {
                for (const item of lineItems) {
                    item.customerId = accountId;
                    context.commit('ADD_CART_ITEM', new LineItem(item));
                }
                await context.dispatch('alignPrice');
            }
            // use local to replace API cart
            else if (accountId) {
                const fullOrder = await replaceCart(context.getters.getFullOrder);
                context.commit('CLEAR_CART');
                context.commit('UPDATE_PRICES', fullOrder);
                context.commit('UPDATE_ESTIMATED_TIME', fullOrder.orderHeader?.submitResult.promiseTime.date);
                for (const item of fullOrder.orderDetails?.lineItems ?? []) {
                    context.commit('ADD_CART_ITEM', item);
                }
            }
        },
        resetCart(context: ActionContext<OrderState, any>, lineItems: LineItem[]): void {
            const accountId = context.getters.getAccountId;
            context.commit('CLEAR_CART');

            lineItems.forEach((item) => {
                item.customerId = accountId;
                context.commit('ADD_CART_ITEM', new LineItem(item));
            });
            replaceLocalStorage(localStorageEnum.cart, context.getters.getCartItems, 1);
            context.dispatch('alignPrice');
        },
        addDonation(context: ActionContext<OrderState, any>, item: LineItem): void {
            if (!item && context.getters.getCartDonation) {
                context.dispatch('deleteCartItem', context.getters.getCartDonation);
            } else if (context.getters.getCartDonation) {
                item.lineitemId = context.getters.getCartDonation.lineitemId;
                context.dispatch('updateCartItem', item);
            } else {
                context.dispatch('addCartItem', item);
            }
        },
        removeDonation(context: ActionContext<OrderState, any>): void {
            if (context.getters.getCartDonation) {
                context.dispatch('deleteCartItem', context.getters.getCartDonation);
            }
        },
        async addCartItem(context: ActionContext<OrderState, any>, item: LineItem): Promise<LineItem[]> {
            const { trackAddItem } = useGoogleAnalytics();

            try {
                context.commit('ADD_CART_ITEM', item);
                const accountId = context.getters.getAccountId;

                if (accountId) {
                    item.customerId = accountId;
                    const res = await addToCart(item);
                    item.lineitemId = res.lineItems[res.lineItems.length - 1].lineitemId;
                    trackAddItem(item.name);
                    context.commit('UPDATE_ESTIMATED_TIME', res.promiseTime);
                    await context.dispatch('alignPrice');
                } else {
                    item.lineitemId = context.getters.getCartItems.length > 0 ? Math.max(...context.getters.getCartItems.map((item) => item.lineitemId ?? 0)) + 1 : 0;
                    trackAddItem(item.name);
                    await context.dispatch('alignPrice');
                }

                return context.getters.getCartItems;
            } catch (error) {
                throw errorToast(error as string);
            }
        },

        addOrderToCart(context: ActionContext<OrderState, any>, order: HistoryOrder): Promise<void> {
            const { trackAddItem } = useGoogleAnalytics();

            return new Promise((resolve, reject) => {
                const accountId = context.getters.getAccountId;
                if (accountId) {
                    addOrderToCart(order.orderHeaderId, accountId)
                        .then(
                            (res: AddToCartRes) => {
                                context.commit('CLEAR_CART'); // clear cart out, then replace with api response
                                //
                                // TODO: Force users to select a location if one is not already and they are attempting to re-order
                                res.lineItems.forEach((item) => {
                                    context.commit('ADD_CART_ITEM', new LineItem(item));
                                    trackAddItem(item.name);
                                });

                                context.commit('UPDATE_ESTIMATED_TIME', res.promiseTime);
                                context.dispatch('alignPrice');
                                resolve(context.getters.getCartItems);
                            },
                            (error) => reject(errorToast(error))
                        )
                        .catch((error) => reject(errorToast(error)));
                }
            });
        },
        updateCartItem(context: ActionContext<OrderState, any>, item: LineItem): Promise<LineItem[]> {
            return new Promise((resolve, reject) => {
                const accountId = context.getters.getAccountId;
                if (accountId) {
                    item.customerId = accountId;
                    updateCartItem(item)
                        .then(
                            (res) => {
                                context.commit('UPDATE_CART_ITEM', item);
                                context.commit('UPDATE_ESTIMATED_TIME', res.promiseTime);
                                context.dispatch('alignPrice');
                                resolve(context.getters.getCartItems);
                            },
                            (error) => reject(errorToast(error))
                        )
                        .catch((error) => reject(errorToast(error)));
                } else {
                    context.commit('UPDATE_CART_ITEM', item);
                    context.dispatch('alignPrice');
                    resolve(context.getters.getCartItems);
                }
            });
        },
        deleteCartItem(context: ActionContext<OrderState, any>, item: LineItem): Promise<LineItem> {
            const { trackRemoveItem } = useGoogleAnalytics();
            return new Promise((resolve, reject) => {
                const accountId = context.getters.getAccountId;

                if (accountId) {
                    item.customerId = accountId;
                    deleteFromCart(item)
                        .then(
                            (res) => {
                                context.commit('REMOVE_CART_ITEM', item);
                                trackRemoveItem(item.name);
                                context.commit('UPDATE_ESTIMATED_TIME', res.promiseTime);
                                context.dispatch('alignPrice');
                                resolve(item);
                            },
                            (error) => reject(errorToast(error))
                        )
                        .catch((error) => reject(errorToast(error)));
                } else {
                    context.commit('REMOVE_CART_ITEM', item);
                    trackRemoveItem(item.name);
                    context.dispatch('alignPrice');
                    resolve(item);
                }
            });
        },
        updateTip(context: ActionContext<OrderState, any>, tip: number): void {
            context.commit('UPDATE_TIP', tip);
            replaceLocalStorage(localStorageEnum.grandTotal, context.getters.getGrandTotal, 0.04); // Dynatrace Data
        },
        alignPrice(context: ActionContext<OrderState, any>) {
            if (context.getters.getSkipPriceAlignment) return;
            if (context.getters.getCartQuantity == 0) return;

            return new Promise((resolve, reject) =>
                orderPrice(context.getters.getFullOrder)
                    .then((fullOrder) => {
                        context.commit('UPDATE_PRICES', fullOrder);
                        context.commit('UPDATE_ESTIMATED_TIME', fullOrder.orderHeader?.submitResult.promiseTime.date);
                        context.commit('UPDATE_ITEM_PRICES', fullOrder);
                        context.dispatch('updateLatestMessage', fullOrder);
                        context.dispatch('realignCoupons', fullOrder);
                        replaceLocalStorage(localStorageEnum.grandTotal, context.getters.getGrandTotal, 0.04); // Dynatrace Data
                        resolve(fullOrder);
                    })
                    .catch((err) => reject(err))
                    .finally(() => {
                        replaceLocalStorage(localStorageEnum.itemsInCart, context.getters.getCartQuantity, 0.04); // Dynatrace Data
                    })
            );
        },
        addOrderPayment(context: ActionContext<OrderState, any>, payment: any): void {
            context.commit('REMOVE_ORDER_PAYMENT_TYPE', payment.paymentType);
            context.commit('ADD_ORDER_PAYMENT', payment);
        },
        updateOrderPaymentAmount(context: ActionContext<OrderState, any>, total: any): void {
            context.commit('UPDATE_ORDER_PAYMENT_AMOUNT', total);
        },
        clearOrderPayments(context: ActionContext<OrderState, any>) {
            context.commit('CLEAR_ORDER_PAYMENTS');
        },
        removeOrderPayment(context: ActionContext<OrderState, any>, payment: Payment): void {
            context.commit('REMOVE_ORDER_PAYMENT', payment);
        },
        // updateDonation(context: ActionContext<OrderState, any>, donation: MenuItem): void {
        // const existingDonation = context.state.cartItems.find((c) => c.item.itemName === MenuCategoryEnum.donations.name);
        // if (existingDonation) {
        //   context.commit("REMOVE_CART_ITEM", existingDonation);
        // }
        // if (donation) {
        //   context.commit("ADD_CART_ITEM", new LineItem({ item: donation }));
        // }
        // },

        updateEstimatedTime(context: ActionContext<OrderState, any>, date: string): void {
            context.commit('UPDATE_ESTIMATED_TIME', date);
        },
        updateLatestMessage(context: ActionContext<OrderState, any>, order: FullOrder): void {
            if (!['/cart', '/checkout'].includes(window.location.pathname)) return;
            //@ts-ignore
            const newMessage = order.orderHeader?.submitResult?.messages[0];
            if (newMessage && newMessage !== context.state.latestMessage) errorToast(newMessage);
            context.commit('UPDATE_LATEST_MESSAGE', newMessage);
        },
        realignCoupons(context: ActionContext<OrderState, any>, order: FullOrder): void {
            // only remove coupons from cart and checkout pages.
            if (!['/cart', '/checkout'].includes(window.location.pathname)) return;
            // Remove Coupons that came back with the invalid error message
            // This is a one off message the captures valid coupons that are turned off at the location level
            const invalidCouponMessage = order.orderHeader.submitResult.messages.find((message) => message.match(/C001: Order contains invalid coupons/));
            const invalidCouponArray = invalidCouponMessage?.match(/{.+}/)[0].replace('{', '').replace('}', '').split(',') ?? [];
            invalidCouponArray.forEach((couponId) => {
                const removeIndex = order.orderDetails.coupons.findIndex((coupon) => coupon.phoenixCode == couponId);
                order.orderDetails.coupons.splice(removeIndex, 1);
            });

            context.commit('REPLACE_ALL_COUPONS', order.orderDetails.coupons);
        },
        setOrderProcessing(context: ActionContext<OrderState, any>, status: boolean): void {
            context.commit('SET_ORDER_PROCESSING', status);
        },
        submitOrder(context: ActionContext<OrderState, any>, requestOrder: FullOrder): Promise<any> {
            context.commit('SET_ORDER_PROCESSING', true);
            context.commit('SET_ORDER_ERROR');
            const { trackOrderPlacement, trackOrderPlacementError, trackProductPurchase, trackCouponPurchase } = useGoogleAnalytics();
            // Reset Time if asap order
            const orderDetail: OrderLogistics = requestOrder.orderDetails.orderLogistics;
            if (stringToBool(orderDetail.isImmediate)) {
                orderDetail.time = DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE);
                // set payload data:
                requestOrder.orderDetails.orderLogistics.time = orderDetail.time;
                // set store data:
                context.dispatch('initStoreLocation', orderDetail ? new OrderLogistics(orderDetail) : undefined);
            } // END reset time if asap order

            return new Promise((resolve, reject) => {
                submitOrder(requestOrder)
                    .then((res) => {
                        // fetchOrderDetail(res.orderHeaderID)
                        //   .then((responseOrder) => {
                        // todo fetchOrderDetail should be returning all of this.. but for now, rebuild from memory
                        const confirmation = buildOrderConfirmation(requestOrder, res);
                        context.commit('SUBMITTED_ORDER', confirmation);
                        context.commit('CLEAR_CART');
                        const storedOrders = getLocalStorage(localStorageEnum.confirmation) ?? [];
                        setLocalStorage(localStorageEnum.confirmation, [...storedOrders, confirmation], 1);
                        resolve(confirmation);

                        confirmation.orderDetails.lineItems.forEach((item) => trackProductPurchase(item.id, item.displayName, item.price, item.quantity));
                        confirmation.orderDetails.coupons.forEach((coupon) => trackCouponPurchase(coupon.code, coupon.name));
                        trackOrderPlacement(confirmation.orderHeaderID, confirmation.orderDetails.taxTotal, confirmation.orderDetails.grandTotal);

                        if (context.getters.getAccount?.rewardsMemberId && !context.getters.getAccount?.isVisitor) {
                            successToastDetail({
                                message: `<h1 class='mt-0'>We're on it!</h1><img src="${require('@/assets/pep/successPep.png')}"/><br/>Points are on the way.<br/> They will be there by tomorrow.`,
                                position: 'middle',
                                color: 'light',
                                duration: 3000,
                                cssClass: 'rewards-toast',
                            });
                        }
                        if (context.getters.getAccount?.customerId) context.commit('SET_ORDER_HISTORY');
                    })
                    // })
                    .catch((err) => {
                        trackOrderPlacementError(err);
                        reject(context.commit('SET_ORDER_ERROR', err));
                    })
                    .finally(() => context.commit('SET_ORDER_PROCESSING', false));
            });
        },
        // todo use fetchOrderDetail
        fetchSubmittedOrder(context: ActionContext<OrderState, any>, id: string) {
            const foundOrder = getLocalStorage(localStorageEnum.confirmation)?.find((o) => o.orderHeaderID == id);
            context.commit('SUBMITTED_ORDER', foundOrder);
        },
        addCoupon(context: ActionContext<OrderState, any>, coupon: Coupon) {
            context.commit('ADD_COUPON', coupon);
            return context.dispatch('alignPrice');
        },
        removeCoupon(context: ActionContext<OrderState, any>, coupon: Coupon) {
            context.commit('REMOVE_COUPON', coupon);
            context.dispatch('convertBundleToCartItems', coupon.code);
            return context.dispatch('alignPrice');
        },
        resetCoupons(context: ActionContext<OrderState, any>, alignPrice = false) {
            context.commit('RESET_COUPONS');
            if (alignPrice) return context.dispatch('alignPrice');
            return null;
        },
        removeOrderError(context: ActionContext<OrderState, any>) {
            context.commit('SET_ORDER_ERROR', undefined);
        },
        clearCart(context: ActionContext<OrderState, any>) {
            context.commit('CLEAR_CART');
        },
        logoutCart(context: ActionContext<OrderState, any>) {
            context.commit('LOGOUT_CART');
        },
        setStellarInfo(context: ActionContext<OrderState, any>, item: any) {
            context.commit('SET_STELLAR_INFO', item);
        },
        rewardsApply(context: ActionContext<OrderState, any>, data: any) {
            context.commit('SET_REWARDS_DATA', data);
        },
        rewardsModalHandle(context: ActionContext<OrderState, any>, val: boolean) {
            context.commit('SET_REWARDS_MODAL', val);
        },
        flavorLoader(context: ActionContext<OrderState, any>, val: boolean) {
            context.commit('SET_FLAVOR_LOADER', val);
        },
        cartEditLoader(context: ActionContext<OrderState, any>, val: boolean) {
            context.commit('SET_CARTEDIT_LOADER', val);
        },
    },
    getters: {
        getCouponsDiscount: (state: OrderState): number => {
            // @ts-ignore
            const discountArray = state.validCoupons.map((coupon) => parseFloat(coupon.discount));
            if (discountArray.length > 0) {
                const total = discountArray.reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0) ?? 0.0;
                return total;
            }
            return 0.0;
        },
        getDeliveryCharge: (state: OrderState): number => +(state.deliveryCharge ?? 0).toFixed(2),
        getCartItems: (state: OrderState): LineItem[] => state.cartItems,
        getCartItemsSorted: (state: OrderState): LineItem[] =>
            [...state.cartItems]
                .filter((i) => !i.bundleIdentifier)
                .reverse()
                .sort((a, b) => a.lineitemId ?? 0 - (b?.lineitemId ?? 0)),
        getCartBundleItemsSorted: (state: OrderState): LineItem[] => {
            const items = [...state.cartItems].filter((i) => i.bundleIdentifier);
            const groupBy = function (xs, key) {
                return xs.reduce(function (rv, x) {
                    const val = x[key].split('--')[0];
                    (rv[val] = rv[val] || []).push(x);
                    return rv;
                }, {});
            };

            return groupBy(items, 'bundleIdentifier');
        },
        getCartItemsNoDonation: (state: OrderState): LineItem[] => state.cartItems.filter((item) => !item.isDonation).sort((a, b) => a.lineitemId ?? 0 - (b?.lineitemId ?? 0)),
        getCartDonation: (state: OrderState, getters: any): LineItem => getters.getCartItems.find((item) => item.isDonation),
        getCartItemsNameSort: (state: OrderState): LineItem[] => state.cartItems.sort((a, b) => a.name.localeCompare(b.name)),
        getCartQuantity: (state: OrderState): number => state.cartItems.reduce((t, item) => t + +item.quantity, 0),
        getSubTotal: (state: OrderState, getters: any): number => +getters.getCartItems.reduce((prev, curr) => prev + curr.quantity * curr.price, 0).toFixed(2),
        getSubTotalPlusTip: (state: OrderState, getters: any): number =>
            (+getters.getCartItems.reduce((prev, curr) => prev + curr.quantity * curr.price, 0) + getters.getTip + getters.getTax + state.deliveryCharge).toFixed(2),
        getSubTotalNoDonation: (state: OrderState, getters: any): number => +getters.getCartItemsNoDonation.reduce((prev, curr) => prev + curr.quantity * curr.price, 0).toFixed(2),
        getDonationAmount: (state: OrderState): number => state.cartItems.find((c) => c.isDonation)?.price ?? 0,
        getTip: (state: OrderState): number => state.tip,
        getTax: (state: OrderState): number => state.taxTotal,
        getGrandTotal: (state: OrderState, getters: any): number => +getters.getSubTotalPlusTip - getters.getCouponsDiscount,
        getOrderTotals: (state: OrderState, getters: any): OrderTotals => {
            return new OrderTotals({
                tip: getters.getTip,
                tax: getters.getTax,
                cartTotal: getters.getSubTotal,
                donation: getters.getDonationAmount,
                deliveryCharge: state.deliveryCharge,
                grandTotal: getters.getGrandTotal,
            });
        },
        getEstimatedTime: (state: OrderState): DateTime | undefined => state.estimatedTime,
        getValidCoupons: (state: OrderState): Coupon[] => state.validCoupons,
        getPayments: (state: OrderState): Payment[] => state.payments,
        getPaymentCoversTotal: (state: OrderState): boolean => {
            if (state.payments.length === 0) return false;
            if (state.payments[0]?.isCash) return true;
            return true;
            // const paymentTotal = state.payments?.reduce((pay, total) => (total += pay.amount), 0) ?? 0;
            // return paymentTotal === getters.getGrandTotal;
        },
        getOrderProcessing: (state: OrderState): boolean => state.orderProcessing,
        getOrderError: (state: OrderState): string | undefined => state.orderError,
        getSubmittedOrder: (state: OrderState): FullOrder | undefined => state.submittedOrder,
        getPrimaryPayment: (state: OrderState): Payment | undefined => state.payments[0],
        getSecondaryPayment: (state: OrderState): Payment | undefined => state.payments[1],
        getPaymentType: (state: OrderState): PaymentIdEnum | undefined => {
            if (state.payments.length > 1) {
                return PaymentIdEnum.giftCard;
            } else if (state.payments.length == 1) {
                return state.payments[0].paymentType;
            } else {
                return undefined;
            }
        },
        getLatestMessage: (state: OrderState): string => state.latestMessage,
        getStellarInfo: (state: OrderState): any[] => state.stellarInfo,
        getRewardsApplyItem: (state: OrderState): any => state.rewardsData,
        getRewardModal: (state: OrderState): boolean => state.rewardModal,
        getFlavorLoader: (state: OrderState): boolean => state.isLoader,
        getCartEditLoader: (state: OrderState): boolean => state.isEditCartLoader,
        getSkipPriceAlignment: (state: OrderState): boolean => state.skipPriceAlignment,
    },
};

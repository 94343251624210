import { DateTime } from 'luxon';

export function formatAmPm(date: Date): string {
    if (!date) return '';

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutesStr + ' ' + ampm;
    return strTime;
}

export function formatStrAmPm(date: string): string {
    if (!date) return '';
    return formatAmPm(new Date(date));
}

export const weekDaysMap = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
};

export const daysFromNow = (date: DateTime): number => Math.ceil(date?.diffNow('days').days ?? 0);
export const minutesFromNow = (date: DateTime) => Math.ceil(date?.diffNow('minutes').minutes);
export const todayDate = () => DateTime.now().startOf('day').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
export const dateTimeSplit = (dateTime: DateTime) => {
    return { date: dateTime.toFormat('yyyy-LL-dd'), time: dateTime.toLocaleString(DateTime.TIME_24_SIMPLE) };
};

//#region General
export const MOBILE_BREAKPOINT = 960;
export const LOCATION_QUERY_PARAM = 'location';
export const LOCATION_ID_QUERY_PARAM = 'location_id';
export const COUPON_QUERY_PARAM = 'coupon';
export const CODE_QUERY_PARAM = 'code';
export const PERSIST_TRACKING_PARAMS = Object.freeze(['utm_keyword', 'utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'gclid', 'fclid']);
export const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
export const CAL_DAYS = {
    january: [...Array(31).keys()].map((i) => i + 1),
    february: [...Array(29).keys()].map((i) => i + 1),
    march: [...Array(31).keys()].map((i) => i + 1),
    april: [...Array(30).keys()].map((i) => i + 1),
    may: [...Array(31).keys()].map((i) => i + 1),
    june: [...Array(30).keys()].map((i) => i + 1),
    july: [...Array(31).keys()].map((i) => i + 1),
    august: [...Array(31).keys()].map((i) => i + 1),
    september: [...Array(30).keys()].map((i) => i + 1),
    october: [...Array(31).keys()].map((i) => i + 1),
    november: [...Array(30).keys()].map((i) => i + 1),
    december: [...Array(31).keys()].map((i) => i + 1),
};

//#endregion

//#region Location
export const LOCATION_SEARCH_RADIUS = 20;
export const LOCATION_SEARCH_LIMIT = 10;
export const MAX_LOCATION_SEARCH_RADIUS = 50;
export const CLOSING_SOON_MINUTES = 30;
export const DEFAULT_COORDINATES = { latitude: 39.957527, longitude: -82.989611 };
export const DEFAULT_LOCATION_ID = '1318';
//#endregion

//#region Menu
export const PIZZA_TOPPING_LIMIT = 9;
export const CALZATO_TOPPING_LIMIT = 3;
export const DONATION_WEBCAT = 11;
export const SALAD_WEBCAT = 3;
export const LOYALTY_POINTS = 3;
export const WING_WEBCAT = 12;
export const BAKERY_PIZZA_TOPPING_LIMIT = 3;

//#endregion

//#region Checkout
export const CHECK_VALID_LOCATION_INTERVAL = 1000 * 60 * 5; // 5 minute intervals

//#endregion

<template>
    <div v-if="!justModal" :class="{ wait: isLoginProcessing }">
        <ion-item v-if="isLoggedIn && !iconBtn" button lines="none" @click="modalOpen = true" class="bold view-account">
            <ion-label> View Account </ion-label>
        </ion-item>
        <ion-button v-else-if="isLoggedIn" @click="modalOpen = true" fill="clear">
            <ion-icon :icon="require('@/assets/account-loggedin-icon.svg')" color="medium" class="mb-4" style="font-size: 30px" data-cy="desktop-account-icon" />
        </ion-button>
        <ion-button v-else fill="clear" :disabled="isLoginProcessing">
            <router-link :to="`/login?redirect=${$route.path}`">
                <ion-icon v-if="iconBtn" :icon="require('@/assets/account-icon.svg')" color="medium" style="font-size: 25px" data-cy="desktop-account-icon" />
            </router-link>
        </ion-button>
    </div>

    <modalWrapper class="modal-wrapper" title="My Account" v-if="!!account" maxWidth="600px" :open="modalOpen" @close="close" @dismiss="close">
        <template v-slot>
            <accountDetail :account="account" />
            <ion-accordion-group :style="contentStyle" @ionChange="accordionGroupChange($event)" ref="accordionGroup">
                <ion-accordion value="payment">
                    <ion-item slot="header" button>
                        <ion-label>Payment Information</ion-label>
                    </ion-item>
                    <ion-item slot="content">
                        <savedCards :account="account" />
                    </ion-item>
                </ion-accordion>
                <ion-accordion value="addresses">
                    <ion-item slot="header" button>
                        <ion-label>Saved Addresses</ion-label>
                    </ion-item>
                    <addressList slot="content" :addresses="account?.deliveryAddresses" editable deleteable addBtn />
                </ion-accordion>
                <ion-accordion value="communication">
                    <ion-item slot="header" button>
                        <ion-label>Communication Preferences</ion-label>
                    </ion-item>
                    <communicationPref slot="content" :account="account" />
                </ion-accordion>
                <ion-accordion value="history">
                    <ion-item slot="header" button>
                        <ion-label>History</ion-label>
                    </ion-item>
                    <span slot="content">
                        <orderHistory :account="account" @close="close" addOrderBtn />
                    </span>
                </ion-accordion>
            </ion-accordion-group>
        </template>

        <template v-slot:footer>
            <ion-row class="ion-justify-content-center ion-align-items-center account-footer ion-padding-bottom">
                <ion-col v-for="link in toolbarLinks" :key="link.name" class="ion-text-center">
                    <a
                        class="toolbar-link bold"
                        @click="
                            () => {
                                link.click();
                            }
                        "
                        size="small"
                        fill="clear"
                        >{{ link.name }}</a
                    >
                </ion-col>
            </ion-row>
        </template>
    </modalWrapper>
    <confirmationModal
        title="Delete Account"
        header="Are you sure you want to delete your account?"
        message="Deleting your account will erase all your data, including points and any unused rewards. This can't be undone, hon—so please choose wisely."
        confirmationMessage="Delete My Account"
        declineMessage="Nevermind!"
        :modalVisible="showDeleteAccountConfirmation"
        @accept="acceptDeleteAccount"
        @decline="declineDeleteAccount"
        :close="closeDeleteAccountConfirmation"
    />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { IonRow, IonCol, IonButton, IonItem, IonAccordionGroup, IonAccordion, IonLabel, IonIcon } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import accountDetail from '@/components/account/accountDetail.vue';
import communicationPref from '@/components/account/communicationPref.vue';
import addressList from '@/components/addressList.vue';
import savedCards from '@/components/savedCards.vue';
import modalWrapper from '@/components/modals/modalWrapper.vue';
import confirmationModal from './confirmationModal.vue';
import { deleteAccount } from '@/services/accountService';
import orderHistory from '@/components/account/orderHistory.vue';

export default defineComponent({
    props: { iconBtn: Boolean, justModal: Boolean },
    components: {
        IonRow,
        IonCol,
        IonButton,
        IonItem,
        IonAccordionGroup,
        IonAccordion,
        IonLabel,
        IonIcon,
        modalWrapper,
        accountDetail,
        addressList,
        savedCards,
        communicationPref,
        confirmationModal,
        orderHistory,
    },
    emits: ['close'],
    setup(props, { emit }) {
        const router = useRouter();
        const store = useStore();

        const modalOpen = ref(props.justModal);
        const accordionGroup = ref();
        const isLoggedIn = computed(() => store.getters.isLoggedIn);
        const account = computed(() => store.getters.getAccount);
        const isLoginProcessing = computed(() => store.getters.isLoginProcessing);

        // TODO: fix core issue, and remove need for adding margin to scroll with accordian items in a modal
        const contentStyle = ref('margin-bottom: 200px; height: 100%;');
        const accordionGroupChange = (event) => {
            // on updates to communication preferences, don't reset scrolling margin
            if (event.detail.value == 'on') return;
            contentStyle.value = 'margin-bottom: 200px';
            if (event.detail.value) {
                contentStyle.value = `margin-bottom: ${event.target.offsetHeight}px`;
            }
        };

        const close = () => {
            modalOpen.value = false;
            emit('close');
        };

        const showDeleteAccountConfirmation = ref(false);
        const acceptDeleteAccount = () => {
            deleteAccount(account.value.customerId);
            store.dispatch('setLogout');
        };
        const declineDeleteAccount = () => {
            console.log('Declined to Delete Account.');
        };
        const closeDeleteAccountConfirmation = () => {
            showDeleteAccountConfirmation.value = false;
        };

        const toolbarLinks = [
            {
                name: 'Customer Service',
                click: () => {
                    router.push('/customer-service');
                    modalOpen.value = false;
                },
            },
            {
                name: 'Privacy Policy',
                click: () => {
                    router.push('/privacy-policy');
                    modalOpen.value = false;
                },
            },
            {
                name: 'Sign Out',
                click: () => {
                    modalOpen.value = false;
                    store.dispatch('setLogout');
                },
            },
            { name: 'Delete Account', click: () => (showDeleteAccountConfirmation.value = true) },
        ];

        return {
            modalOpen,
            toolbarLinks,
            accordionGroup,
            isLoggedIn,
            account,
            close,
            isLoginProcessing,
            accordionGroupChange,
            contentStyle,
            showDeleteAccountConfirmation,
            acceptDeleteAccount,
            declineDeleteAccount,
            closeDeleteAccountConfirmation,
        };
    },
});
</script>

<style scoped>
.disabled {
    /* pointer-events: none; */
    cursor: wait;
}
ion-modal {
    --max-width: 960px;
    --min-height: 90vh;
}

.view-account {
    --highlight-color-focused: white;
    --inner-border-width: 0;
    font-weight: bold;
    color: var(--ion-color-dark-shade);
    padding-left: 4%;
}

ion-col {
    border-right: 1px solid lightgray;
}

ion-col:last-child {
    border-right: none;
}

.account-footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    rightl: 0px;
    width: 100%;
    padding-top: 15px;
    background-color: var(--ion-color-background);
}
</style>

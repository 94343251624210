import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  slot: "label",
  class: "label-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_input, {
    "label-placement": _ctx.stacked ? 'stacked' : 'floating',
    fill: _ctx.fill,
    placeholder: "(555) 867-5308",
    inputmode: "tel",
    modelValue: _ctx.localModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
    autocomplete: "tel",
    pattern: "tel",
    maxlength: "14",
    color: "medium",
    "error-text": _ctx.error,
    class: "custom",
    mode: "md"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createTextVNode("Phone Number ")),
        (_ctx.required)
          ? (_openBlock(), _createBlock(_component_ion_text, {
              key: 0,
              color: "danger"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("*")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["label-placement", "fill", "modelValue", "error-text"]))
}
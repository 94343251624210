<template>
    <ion-col size="6">
        <ion-select :label-placement="isValidBirthdate ? '' : 'floating'" fill="outline" placeholder="Birth Month" v-model="month" interface="action-sheet" :disabled="isValidBirthdate" mode="md">
            <div slot="label" class="label-color">Birth Month</div>
            <ion-select-option v-for="(month, index) in months" :key="index" :value="index">
                {{ capitalize(month) }}
            </ion-select-option>
        </ion-select>
    </ion-col>

    <ion-col size="6">
        <ion-select
            :label-placement="isValidBirthdate ? '' : 'floating'"
            fill="outline"
            placeholder="Birth Day"
            v-model="day"
            interface="action-sheet"
            :disabled="!numberOfDays || isValidBirthdate"
            mode="md"
        >
            <div slot="label" class="label-color">Birth Day</div>
            <ion-select-option v-for="day in numberOfDays" :key="day" :value="day">
                {{ day }}
            </ion-select-option>
        </ion-select>
    </ion-col>
    <ion-text v-if="isValidBirthdate" class="ion-text-center full-width mb-8 py-8"> If you need to make a change to your birthday, please <a href="/customer-service">contact us</a>. </ion-text>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { IonSelect, IonSelectOption, IonCol, IonText } from '@ionic/vue';
import { CAL_DAYS, MONTHS } from '@/constants';
import { capitalize } from '@/services/stringService';
import { DateTime } from 'luxon';

export default defineComponent({
    props: { birthdateString: { type: String, required: true } },
    emits: ['updateBirthdate'],
    setup(props, { emit }) {
        const months = MONTHS;
        const birthdate = DateTime.fromISO(props.birthdateString);
        const isValidBirthdate = birthdate.isValid;

        // month index ex. (0 is 'jan' && 11 is 'dec')
        const month = ref(0);
        const day = ref(1);
        const year = ref(1964);

        // luxon returns actual month number rather than index,
        // subtracting 1 to match index.
        month.value = birthdate.month - 1;
        day.value = birthdate.day;

        const numberOfDays = computed(() => CAL_DAYS[months[month.value]]);

        watch(month, (m) => {
            if (m !== null && !day.value) day.value = 1;
            else if (m === 1 && day.value >= 29) day.value = 29;
            else if (day.value > numberOfDays.value.length) day.value = 30;
            else emit('updateBirthdate', getBirthDate());
        });

        watch(day, () => {
            emit('updateBirthdate', getBirthDate());
        });

        const getBirthDate = () => {
            // return new Date(`${month.value + 1}/${day.value}/${year.value}`).toISOString().slice(0, 10);

            const date = new Date(year.value, month.value, day.value);
            return date.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
        };

        return { months, day, month, numberOfDays, capitalize, isValidBirthdate };
    },
    components: { IonSelect, IonSelectOption, IonCol, IonText },
});

// import
// import Birthday from "@/components/inputs/birthday.vue";

// component
// <birthday :birthdateString="account.birthdate" @updateBirthdate="updateBirthdate($event)" />

// listener
//  const updateBirthdate = (date) => { console.log("updating Birthdate: ", date); };
</script>

<style scoped>
.b-day-text {
    text-align: center;
}
.label-color {
    color: #92949c;
}
</style>

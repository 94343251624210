export interface NotificationState {
    link: string;
    linkText: string;
    message: string;
    show: boolean;
    type: string;
    duration: number | null;
}

const NotificationState: NotificationState = {
    link: '',
    linkText: '',
    message: '',
    show: false,
    type: '',
    duration: null,
};

export default {
    state: NotificationState,
    mutations: {
        SHOW_NOTIFICATION(state: NotificationState, notification: any) {
            state.link = notification.link;
            state.linkText = notification.linkText;
            state.message = notification.message;
            state.show = true;
            state.type = notification.type;
            state.duration = notification.duration;
            if (state.duration) {
                setTimeout(() => {
                    state.show = false;
                }, state.duration);
            }
        },
        HIDE_NOTIFICATION(state: NotificationState) {
            state.link = '';
            state.linkText = '';
            state.message = '';
            state.show = false;
            state.type = '';
            state.duration = 5000;
        },
    },
    actions: {
        hideNotification({ commit }: any) {
            commit('HIDE_NOTIFICATION');
        },
        notify({ commit }: any, notification: any) {
            commit('SHOW_NOTIFICATION', {
                link: notification.link,
                linkText: notification.linkText,
                message: notification.message,
                show: true,
                type: 'success',
            });
            if (!notification.static) {
                setTimeout(() => {
                    commit('HIDE_NOTIFICATION');
                }, notification.duration ?? 5000);
            }
        },
    },
    getters: {
        getNotification: (state: NotificationState) => {
            return state;
        },
    },
};

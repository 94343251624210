import { ActionContext } from 'vuex';
import { Geolocation } from '@capacitor/geolocation';
import { Address, Coordinate, CustomerAddress } from '@/models/addressModels';
import {
    addAccountAddressPost,
    editAccountAddressPost,
    addCreditCard,
    deleteAddress,
    deleteStoredCard,
    fetchAccountInfo,
    fetchStoredCards,
    updateStoredCard,
    rewardsOptIn,
    fetchMemberResponses,
} from '@/services/accountService';
import { deleteLocalStorage, getLocalStorage, localStorageEnum, replaceLocalStorage } from '@/services/localStorageService';
import { DEFAULT_COORDINATES } from '@/constants';
import useAuth from '@/services/authService';
import useVault from '@/services/vaultService';
import router from '@/router';
import { CheckoutRequest } from '@/models/checkoutModels';
import { validateDeliveryAddress } from '@/services/locationService';
import { Account, AccountLoyaltyInfo } from '@/models/account/authModels';
import { alertController } from '@ionic/vue';
import { CreditCard } from '@/models/PaymentModels';
import { errorToast, successToast } from '@/services/pageService';
import { initCheetah } from '@/services/cheetah/stellar-init';
import { nullOrEmpty } from '@/services/stringService';
import { HistoryOrder, OrderLogistics } from '@/models/cartModels';
import { orderHistory } from '@/services/orderService';

const { isAuthenticated, getCognitoUserInfo, logout, isAccessTokenExpired, isRefreshTokenAvailable, refreshSession, clearStorage } = useAuth();
const { initializeUnlockMode } = useVault();

export interface AccountState {
    loginProcessing: boolean;
    loyaltyProcessing: boolean;
    loyaltyInfoProcessing: boolean;
    createAccountProcessing: boolean;
    account?: Account;
    orderHistory?: HistoryOrder[];
    guest: Account;
    userLocation?: Coordinate;
    isAuthenticated: boolean;
    isGeoLocationOn: boolean;
    appInfo?: any;
}

const AccountState: AccountState = {
    loginProcessing: false,
    loyaltyProcessing: false,
    loyaltyInfoProcessing: false,
    createAccountProcessing: false,
    isAuthenticated: false,
    isGeoLocationOn: false,
    guest: new Account(),
};

const initUserLogin = (context) => {
    getCognitoUserInfo()
        .then(async (user) => {
            if (user) {
                context.commit('SET_LOGIN_PROCESSING', true);
                fetchAccountInfo(1, 0)
                    .then((res) => {
                        context.commit('SET_ACCOUNT_INFO', res);
                        const cart = getLocalStorage(localStorageEnum.cart);
                        if (cart && cart.lineItems) {
                            context.dispatch('initCart', cart.lineItems);
                        }
                        context.commit('SET_ORDER_HISTORY');

                        context.dispatch('updateEstimatedTime', res.cart.orderHeader?.submitResult.promiseTime.date);
                        // @ts-ignore
                        context.dispatch('initStoreLocation', new OrderLogistics(res.cart.orderDetails?.orderLogistics));
                        context.commit('SET_LOGIN_PROCESSING', false);
                        context.dispatch('setSavedCards').catch(() => false);
                        // check if access token expired before adding to dom
                        if (res?.rewardsMemberId && res?.rewardsMemberId !== 'Pending') {
                            context.commit('SET_LOYALTY_PROCESSING', true);
                            context.commit('SET_LOYALTY_INFO_PROCESSING', true);
                            fetchMemberResponses()
                                .then((res) => {
                                    context.commit('SET_ACCOUNT_LOYATLY_INFO', res);
                                    if (res.cheetahMemberSso?.asStellarMemberState) {
                                        const cheetahCreds = document.createElement('script');
                                        cheetahCreds.text = `${res.cheetahMemberSso.asStellarMemberState}`;
                                        document.head.appendChild(cheetahCreds);
                                        initCheetah(); // applies cheetah initialization script
                                    }
                                    context.commit('SET_LOYALTY_PROCESSING', false);
                                    context.commit('SET_LOYALTY_INFO_PROCESSING', false);
                                })
                                .catch(() => {
                                    context.commit('SET_LOYALTY_PROCESSING', false);
                                    context.commit('SET_LOYALTY_INFO_PROCESSING', false);
                                });
                        }
                    })
                    .catch((err) => {
                        context.commit('SET_LOGIN_PROCESSING', false);
                        context.dispatch('setLogout');
                        router.push('/');

                        errorToast(err);
                    })
                    .finally(() => context.commit('SET_LOGIN_PROCESSING', false));
            }
        })
        .catch((err) => console.warn('Login Error: ', err))
        .finally(() => {
            context.commit('SET_LOGIN_PROCESSING', false);
        });
};

export default {
    state: AccountState,
    mutations: {
        SET_LOGIN_PROCESSING: (state: AccountState, isProcessing: boolean): void => {
            state.loginProcessing = isProcessing;
        },
        SET_LOGIN_REDIRECT_URL: (state: AccountState, url: string): void => {
            replaceLocalStorage(localStorageEnum.loginRedirectUrl, url, 1);
        },
        RESET_LOGIN_REDIRECT_URL: (): void => {
            replaceLocalStorage(localStorageEnum.loginRedirectUrl, '/', 1);
        },
        SET_LOYALTY_PROCESSING: (state: AccountState, isProcessing: boolean): void => {
            state.loyaltyProcessing = isProcessing;
        },
        SET_LOYALTY_INFO_PROCESSING: (state: AccountState, isProcessing: boolean): void => {
            state.loyaltyInfoProcessing = isProcessing;
        },
        CLEAR_ACCOUNT_INFO: (state: AccountState): void => {
            state.account = undefined;
            state.orderHistory = undefined;
            state.isAuthenticated = false;
            deleteLocalStorage(localStorageEnum.customerName); // Dynatrace Data
            deleteLocalStorage(localStorageEnum.customerPhone); // Dynatrace Data
        },
        SET_ACCOUNT_INFO: (state: AccountState, info: Account): void => {
            // holding onto Loyalty data to avoid overwrite.
            // use context.commit("SET_ACCOUNT_LOYATLY_INFO", res); to overwrite loyalty data
            const memberOffers = state.account?.cheetahMemberOffers;
            const memberResponses = state.account?.cheetahMemberResponses;
            const memberRewards = state.account?.cheetahMemberRewards;
            const memberSso = state.account?.cheetahMemberSso;
            const memberSummary = state.account?.cheetahMemberSummary;

            state.account = info;

            if (memberOffers) state.account.cheetahMemberOffers = memberOffers;
            if (memberResponses) state.account.cheetahMemberResponses = memberResponses;
            if (memberRewards) state.account.cheetahMemberRewards = memberRewards;
            if (memberSso) state.account.cheetahMemberSso = memberSso;
            if (memberSummary) state.account.cheetahMemberSummary = memberSummary;

            state.isAuthenticated = !!info;
            replaceLocalStorage(localStorageEnum.customerName, info?.name, 0.04); // Dynatrace Data
            replaceLocalStorage(localStorageEnum.customerPhone, info?.phoneNumber, 0.04); // Dynatrace Data
        },
        SET_ORDER_HISTORY: (state: AccountState): void => {
            if (!state.account) return;
            orderHistory(state.account?.customerId).then((h) => (state.orderHistory = h.map((o) => new HistoryOrder(o))));
        },
        SET_ACCOUNT_LOYATLY_INFO: (state: AccountState, info: AccountLoyaltyInfo): void => {
            if (state.account) {
                state.account.cheetahMemberSummary = info.cheetahMemberSummary;
                state.account.cheetahMemberResponses = info.cheetahMemberResponses;

                state.account.cheetahMemberOffers = info.cheetahMemberOffers;
                state.account.cheetahMemberSso = info.cheetahMemberSso;
                state.account.cheetahMemberRewards = info.cheetahMemberRewards;
            }
        },
        SET_USER_LOCATION: (state: AccountState, location: Coordinate) => {
            state.userLocation = new Coordinate(location);
        },
        ADD_ACCOUNT_ADDRESS: (state: AccountState, location: CustomerAddress) => state.account?.deliveryAddresses.push(location),
        EDIT_ACCOUNT_ADDRESS: (state: AccountState, location: CustomerAddress) => {
            const editedIndex = state.account?.deliveryAddresses.findIndex((address: CustomerAddress) => address.addressId == location.addressId);
            //@ts-ignore
            state.account?.deliveryAddresses.splice(editedIndex, 1, location);
        },
        DELETE_ACCOUNT_ADDRESS: (state: AccountState, location: CustomerAddress) => {
            const removeIndex = state.account?.deliveryAddresses?.findIndex((address) => address == location);
            if (removeIndex != undefined) state.account?.deliveryAddresses.splice(removeIndex, 1);
        },
        ADD_TEMP_ACCOUNT_DETAIL: (state: AccountState, details: CheckoutRequest): void => {
            if (state.account) {
                state.account.tempFirstName = details.firstName;
                state.account.tempLastName = details.lastName;
                state.account.tempPhone = details.phoneNumber;
                state.account.tempEmail = details.email;
                state.account.isMarketingEmail = details.isMarketingEmail;
            } else {
                state.guest.firstName = details.firstName;
                state.guest.lastName = details.lastName;
                state.guest.tempPhone = details.phoneNumber;
                state.guest.tempEmail = details.email;
                state.guest.isMarketingEmail = details.isMarketingEmail;
            }
            replaceLocalStorage(localStorageEnum.customerName, `${details?.firstName} ${details?.lastName}`, 0.4); // Dynatrace Data
            replaceLocalStorage(localStorageEnum.customerPhone, details?.phoneNumber, 0.4); // Dynatrace Data
        },
        ADD_SAVED_CARD: (state: AccountState, card: CreditCard) => state.account?.savedCards.push(card),
        SET_SAVED_CARDS: (state: AccountState, cards: CreditCard[]) => {
            if (state.account) {
                state.account.savedCards = cards;
            }
        },
        UPDATE_SAVED_CARD: (state: AccountState, card: CreditCard) => {
            if (state.account) {
                const cardIndex = state.account.savedCards.findIndex((c: CreditCard) => c.creditCardId == card.creditCardId);
                state.account.savedCards[cardIndex] = card;
            }
        },
        DELETE_SAVED_CARD: (state: AccountState, card: CreditCard) => {
            const { savedCards } = state.account ?? {};
            const creditCardId = card?.creditCardId;
            if (savedCards && creditCardId) {
                const removeIndex = savedCards.findIndex((c) => c.creditCardId === creditCardId);
                if (removeIndex !== -1) {
                    savedCards.splice(removeIndex, 1);
                }
            }
        },
        UPDATE_GEOLOCATION_STATUS: (state: AccountState, isOn: boolean) => {
            state.isGeoLocationOn = isOn;
        },
        SET_APP_INFO: (state: AccountState, info: any) => {
            state.appInfo = info;
        },
    },
    actions: {
        /**
         * Loads logged-in user account data from api or guest data if stored local storage.
         * This cascades into loading selected location, order details, cart, and menu if applicable.
         * - If auth token is expired, this is where unlock mode for native device is set to use biometric login
         */
        async initUserData(context: ActionContext<AccountState, any>): Promise<void> {
            context.dispatch('setGeoLocation'); // ask for users geoLocation;
            await initializeUnlockMode(); // set the identity vaults unlock mode;
            await isAuthenticated(); // check if authed, will use refresh token if available
            const hasExpiredAccessToken = await isAccessTokenExpired();
            if (hasExpiredAccessToken) {
                if (await isRefreshTokenAvailable()) {
                    await refreshSession();
                } else {
                    await clearStorage();
                }
            } else if (await isAuthenticated()) {
                initUserLogin(context);
            } else {
                const cart = getLocalStorage(localStorageEnum.cart);
                if (cart && cart?.lineItems) {
                    context.dispatch('initCart', cart?.lineItems);
                }
                let orderDetail: OrderLogistics = getLocalStorage(localStorageEnum.orderLogistics);
                if (!orderDetail) {
                    // If no orderDetail exists in localStorage, add it.
                    replaceLocalStorage(localStorageEnum.orderLogistics, context.getters.getOrderLogistics, 0.1);
                    orderDetail = getLocalStorage(localStorageEnum.orderLogistics);
                }
                context.dispatch('initStoreLocation', orderDetail ? new OrderLogistics(orderDetail) : undefined);
            }
        },
        setGeoLocation(context: ActionContext<AccountState, any>): void {
            Geolocation.getCurrentPosition({ timeout: 30000 })
                .then((position) => {
                    context.commit('SET_USER_LOCATION', position.coords);
                    context.commit('UPDATE_GEOLOCATION_STATUS', true);
                })
                .catch(() => {
                    context.commit('UPDATE_GEOLOCATION_STATUS', false);
                });
        },
        setLogin(context: ActionContext<AccountState, any>) {
            if (context.getters.isLoginProcessing || context.getters.loggedIn) return;
            context.commit('SET_LOGIN_PROCESSING', true);
            initUserLogin(context);
        },
        setLoginRedirectUrl(context: ActionContext<AccountState, any>, url = '/') {
            context.commit('SET_LOGIN_REDIRECT_URL', url);
        },
        async setLogout(context: ActionContext<AccountState, any>, redirectUrl = '/location'): Promise<void> {
            await clearStorage();
            deleteLocalStorage(localStorageEnum.orderTip);
            context.commit('CLEAR_ACCOUNT_INFO');
            context.dispatch('logoutCart');
            logout()
                .then(() => router.push(redirectUrl))
                .catch(() => clearStorage());
        },
        addUserAddress(context: ActionContext<AccountState, any>, request: CustomerAddress): Promise<Address> {
            request.customerId = context.state.account?.customerId ?? '';
            return new Promise((resolve, reject) => {
                validateDeliveryAddress(new Address(request))
                    .then(async (valid) => {
                        request.storeNumber = valid.storeNumber;
                        if (valid.message.includes('address is corrected.')) {
                            const address1 = valid.address.address1;
                            const address2 = valid.address.address2 ?? '';
                            const city = valid.address.city ?? '';
                            const state = valid.address.state ?? '';
                            const zipCode = valid.address.zip ?? valid.address.zipcode ?? '';

                            const alert = await alertController.create({
                                header: `Address Suggestion`,
                                message: `${address1} ${address2}<br>${city}, ${state} ${zipCode}`,
                                backdropDismiss: true,
                                cssClass: 'valid-address-alert',
                                htmlAttributes: { ['style']: 'width: 500px;' },
                                buttons: [
                                    {
                                        text: 'Check Address',
                                        role: 'cancel',
                                        handler: () => reject('Please validate your delivery address.'),
                                    },
                                    {
                                        text: 'Use This Address',
                                        role: 'confrim',
                                        handler: () => {
                                            //@ts-ignore // set validated/corrected address fields
                                            request.zip = valid.address.zipcode ?? valid.address.zipCode ?? valid.address.zip;
                                            request.address1 = valid.address.address1;
                                            request.city = valid.address.city;
                                            request.state = valid.address.state;
                                            addAccountAddressPost(request)
                                                .then((res) => {
                                                    request.addressId = res.addressId;
                                                    context.commit('ADD_ACCOUNT_ADDRESS', request);
                                                    context.dispatch('foundDeliveryLocation', valid);
                                                    resolve(valid.address);
                                                })
                                                .catch((err) => reject(err));
                                        },
                                    },
                                ],
                            });
                            return alert.present();
                        } else {
                            addAccountAddressPost(request)
                                .then((res) => {
                                    //@ts-ignore // set validated/corrected address fields
                                    request.zip = valid.address.zipcode ?? valid.address.zipCode ?? valid.address.zip;
                                    request.address1 = valid.address.address1;
                                    request.city = valid.address.city;
                                    request.state = valid.address.state;
                                    request.addressId = res.addressId;
                                    context.commit('ADD_ACCOUNT_ADDRESS', request);
                                    context.dispatch('foundDeliveryLocation', valid);
                                    resolve(valid.address);
                                })
                                .catch((err) => reject(err));
                        }
                    })
                    .catch((err) => reject(err));
            });
        },
        editUserAddress(context: ActionContext<AccountState, any>, request: CustomerAddress): Promise<Address> {
            request.customerId = context.state.account?.customerId ?? '';
            return new Promise((resolve, reject) => {
                validateDeliveryAddress(new Address(request))
                    .then(async (valid) => {
                        request.storeNumber = valid.storeNumber;
                        if (valid.message.includes('address is corrected.')) {
                            const address1 = valid.address.address1;
                            const address2 = valid.address.address2 ?? '';
                            const city = valid.address.city ?? '';
                            const state = valid.address.state ?? '';
                            const zipCode = valid.address.zip ?? valid.address.zipcode ?? '';

                            const alert = await alertController.create({
                                header: `Address Suggestion`,
                                message: `${address1} ${address2}<br>${city}, ${state} ${zipCode}`,
                                backdropDismiss: true,
                                cssClass: 'valid-address-alert',
                                htmlAttributes: { ['style']: 'width: 500px;' },
                                buttons: [
                                    {
                                        text: 'Check Address',
                                        role: 'cancel',
                                        handler: () => reject('Please validate your delivery address.'),
                                    },
                                    {
                                        text: 'Use This Address',
                                        role: 'confrim',
                                        handler: () => {
                                            //@ts-ignore // set validated/corrected address fields
                                            request.zip = valid.address.zipcode ?? valid.address.zipCode ?? valid.address.zip;
                                            request.address1 = valid.address.address1;
                                            request.city = valid.address.city;
                                            request.state = valid.address.state;
                                            editAccountAddressPost(request)
                                                .then(() => {
                                                    context.commit('EDIT_ACCOUNT_ADDRESS', request);
                                                    context.dispatch('foundDeliveryLocation', valid);
                                                    resolve(valid.address);
                                                })
                                                .catch((err) => reject(err));
                                        },
                                    },
                                ],
                            });
                            return alert.present();
                        } else {
                            editAccountAddressPost(request)
                                .then(() => {
                                    context.commit('EDIT_ACCOUNT_ADDRESS', request);
                                    context.dispatch('foundDeliveryLocation', valid);
                                    resolve(valid.address);
                                })
                                .catch((err) => reject(err));
                        }
                    })
                    .catch((err) => reject(err));
            });
        },
        deleteUserAddress(context: ActionContext<AccountState, any>, request: CustomerAddress): Promise<any> {
            return new Promise((resolve, reject) => {
                deleteAddress(request.asDelete)
                    .then(() => resolve(context.commit('DELETE_ACCOUNT_ADDRESS', request)))
                    .catch((err) => reject(err));
            });
        },
        setTempAccountDetails(context: ActionContext<AccountState, any>, details: CheckoutRequest): Promise<any> {
            context.commit('ADD_TEMP_ACCOUNT_DETAIL', details);
            return Promise.resolve();
        },
        addSavedCard(context: ActionContext<AccountState, any>, card: CreditCard): Promise<CreditCard | void> {
            return addCreditCard(card)
                .then((res) => {
                    card.creditCardId = res.creditCardID;
                    context.commit('ADD_SAVED_CARD', card);
                    Promise.resolve(card);
                })
                .catch((error) => Promise.reject(error));
        },
        setSavedCards(context: ActionContext<AccountState, any>): Promise<CreditCard[] | void> {
            return fetchStoredCards(context.state.account?.customerId ?? '')
                .then((card) => {
                    context.commit('SET_SAVED_CARDS', card);
                    Promise.resolve(card);
                })
                .catch((error) => Promise.reject(error));
        },
        updateSavedCard(context: ActionContext<AccountState, any>, card: CreditCard): Promise<CreditCard | void> {
            return updateStoredCard(card)
                .then(() => {
                    context.commit('UPDATE_SAVED_CARD', card);
                })
                .catch((error) => Promise.reject(error));
        },
        deleteSavedCard(context: ActionContext<AccountState, any>, card: CreditCard): Promise<CreditCard | void> {
            return deleteStoredCard(card.asDelete())
                .then(() => {
                    context.commit('DELETE_SAVED_CARD', card);
                })
                .catch((error) => Promise.reject(error));
        },
        rewardsOptIn(context: ActionContext<AccountState, any>, phone: string) {
            const account = context.getters.getAccount;
            if (!nullOrEmpty(phone)) account.phoneNumber = phone;
            return rewardsOptIn(account)
                .then((res) => {
                    context.commit('SET_ACCOUNT_INFO', res);
                    context.commit('SET_ACCOUNT_LOYATLY_INFO', res);
                    successToast('Welcome To Rewards Membership.<br/>Life will never be the same.');
                })
                .catch((error) => errorToast(error));
        },
        refreshAccountInfo(context: ActionContext<AccountState, any>) {
            return new Promise((resolve, reject) => {
                fetchAccountInfo(1, 0)
                    .then((res) => resolve(context.commit('SET_ACCOUNT_INFO', res)))
                    .catch((error) => reject(error()));
            });
        },
        refreshAccountRewardInfo(context: ActionContext<AccountState, any>) {
            return new Promise((resolve, reject) => {
                fetchMemberResponses()
                    .then((res) => resolve(context.commit('SET_ACCOUNT_LOYATLY_INFO', res)))
                    .catch((error) => reject(error()));
            });
        },
        fetchOrderHistory(context: ActionContext<AccountState, any>) {
            context.commit('SET_ORDER_HISTORY');
        },
        setAppInfo(context: ActionContext<AccountState, any>, info) {
            context.commit('SET_APP_INFO', info);
        },
    },
    getters: {
        isLoginProcessing: (state: AccountState): boolean => state.loginProcessing,
        isLoyaltyProcessing: (state: AccountState): boolean => state.loyaltyProcessing,
        isLoyaltyInfoProcessing: (state: AccountState): boolean => state.loyaltyInfoProcessing,
        isLoggedIn: (state: AccountState): boolean => state.isAuthenticated,
        isCreateAccountProcessing: (state: AccountState): boolean => state.createAccountProcessing,
        userCoordinates: (state: AccountState): Coordinate => state.userLocation ?? DEFAULT_COORDINATES,
        getAccount: (state: AccountState): Account | undefined => state.account,
        getOrderHistory: (state: AccountState): HistoryOrder[] | undefined => state.orderHistory,
        getHasOrderHistory: (state: AccountState): boolean => !!state.orderHistory && state.orderHistory.length > 0,
        getOrderHistoryCount: (state: AccountState): number => (state.orderHistory ? state.orderHistory.length : 0),
        getAccountOrGuest: (state: AccountState): Account => state.account ?? state.guest,
        getAccountAddresses: (state: AccountState): CustomerAddress[] => state.account?.deliveryAddresses ?? [],
        getAccountFirstName: (state: AccountState): string | undefined => state.account?.firstName,
        getAccountId: (state: AccountState): string | undefined => state.account?.customerId,
        getAccountSavedCards: (state: AccountState): CreditCard[] => state.account?.savedCards ?? [],
        getAccountDefaultCard: (state: AccountState): CreditCard | undefined => state.account?.savedCards.find((card) => card.isDefaultCard) ?? undefined,
        isGeoLocationOn: (state: AccountState): boolean => state.isGeoLocationOn,
        getLoginRedirectUrl: (): string => getLocalStorage(localStorageEnum.loginRedirectUrl) ?? '/',
        getAppInfo: (state: AccountState): any => state.appInfo,
    },
};

import { daysFromNow } from '@/services/timeService';
import { DateTime } from 'luxon';
class CheetahMember {
    firstName: string;
    lastName: string;
    memberId: string; //Q-000286065,
    memberSince: string;
    referred: boolean;
    visitor: boolean;
    localeCode: string;
    enabledrewards: string;
    constructor(m?: CheetahMember) {
        this.firstName = m?.firstName ?? '';
        this.lastName = m?.lastName ?? '';
        this.memberId = m?.memberId ?? '';
        this.memberSince = m?.memberSince ?? '';
        this.referred = m?.referred ?? false;
        this.visitor = m?.visitor ?? false;
        this.localeCode = m?.localeCode ?? '';
        this.enabledrewards = m?.enabledrewards ?? '';
    }
}
class CheetahTier {
    currentTier?: {
        name: string;
        label: string;
        expirationDate: string;
    };
    tierInProgression: string;
    primaryMetricName: string;
    metrics: [];
    expressionsExist: boolean;
    tiers: {
        id: string;
        name: string;
        label: string;
        reward: string;
        description: string;
        metricThresholds: any;
    }[];
    constructor(t?: CheetahTier) {
        this.currentTier = t?.currentTier;
        this.tierInProgression = t?.tierInProgression ?? '';
        this.primaryMetricName = t?.primaryMetricName ?? '';
        this.metrics = t?.metrics ?? [];
        this.expressionsExist = t?.expressionsExist ?? false;
        this.tiers = t?.tiers ?? [];
    }
}
export class MetricDetail {
    label: string;
    redeemTotal: number;
    balance: number;
    lifeTime: number;
    last12mo: number;
    ytd: number;
    last_30d: number;
    mtd: number;
    today: number;
    constructor(d?: MetricDetail) {
        this.label = d?.label ?? '';
        this.redeemTotal = +(d?.redeemTotal ?? 0);
        this.balance = +(d?.balance ?? 0);
        this.lifeTime = +(d?.lifeTime ?? 0);
        this.last12mo = +(d?.last12mo ?? 0);
        this.ytd = +(d?.ytd ?? 0);
        this.last_30d = +(d?.last_30d ?? 0);
        this.mtd = +(d?.mtd ?? 0);
        this.today = +(d?.today ?? 0);
    }
}
class CheetahMetrics {
    point: MetricDetail;
    Pts: MetricDetail;
    pt: MetricDetail;
    visit: MetricDetail;
    masterPoints: MetricDetail;
    constructor(m?: CheetahMetrics) {
        this.point = new MetricDetail(m?.point);
        this.Pts = new MetricDetail(m?.Pts);
        this.pt = new MetricDetail(m?.pt);
        this.visit = new MetricDetail(m?.visit);
        this.masterPoints = new MetricDetail(m?.masterPoints);
    }
}

export class CheetahSummary {
    member: CheetahMember;
    tier: CheetahTier;
    metrics: CheetahMetrics;
    memberBadges: [];
    permissions: string[];
    segments: string[];

    constructor(data?: CheetahSummary) {
        this.member = new CheetahMember(data?.member);
        this.tier = new CheetahTier(data?.tier);
        this.metrics = new CheetahMetrics(data?.metrics);
        this.memberBadges = data?.memberBadges ?? [];
        this.permissions = data?.permissions ?? [];
        this.segments = data?.segments ?? [];
    }

    get getPoints() {
        return this.metrics.point;
    }
    get getEnabledRewards() {
        return this.member.enabledrewards;
    }
}
export class CheetahReward {
    id: string;
    internalName: string;
    type: string;
    sku?: string;
    rank?: string;
    drawDate?: DateTime;
    points: number;
    metric: string;
    metricDisplayName: string;
    startDate?: string;
    endDate?: DateTime;
    responseStartDate?: string;
    responseEndDate?: string;
    responseSchedule?: string;
    redeemOptions: [];
    heading: string;
    subheading: string;
    body: string;
    subject: string;
    details: string;
    action: string;
    headingColor: string;
    color: string;
    bgcolor: string;
    headingBgcolor: string;
    image1Bg: string;
    layoutId: string;
    sections: [];
    imageUrl: string;
    thumbImageUrl: string;
    originalImageUrl: string;
    awarded: boolean;
    favorited: boolean;
    redeemable: boolean;
    respondable: boolean;
    respondableInfo: {
        value: boolean;
        code: string;
        message: string;
    };
    extraJson: [];
    businessValue: number;
    metricAmount: number;
    metricName: string;
    targetUrl?: string;
    internalUrl: string;
    addressRequired: boolean;
    trackingCode?: string;
    responseMessage: string;
    acceptanceRequired: boolean;
    acceptanceText: string;
    acceptanceUrl?: string;

    diff?: number; // Frontend only
    disabled?: boolean; // Frontend only

    constructor(data: CheetahReward) {
        this.id = data.id;
        this.internalName = data.internalName;
        this.type = data.type;
        this.sku = data.sku;
        this.rank = data.rank;
        this.points = +(data?.points ?? 0);
        this.metric = data.metric;
        this.metricDisplayName = data.metricDisplayName;
        this.drawDate = data.drawDate ? DateTime.fromISO(data.drawDate) : undefined;
        this.startDate = data.startDate ? DateTime.fromISO(data.startDate) : undefined;
        this.endDate = data.endDate ? DateTime.fromISO(data.endDate) : undefined;
        this.responseStartDate = data.responseStartDate ? DateTime.fromISO(data.responseStartDate) : undefined;
        this.responseEndDate = data.responseEndDate ? DateTime.fromISO(data.responseEndDate) : undefined;
        this.responseSchedule = data.responseSchedule;
        this.redeemOptions = data.redeemOptions;
        this.heading = data.heading;
        this.subheading = data.subheading;
        this.body = data.body;
        this.subject = data.subject;
        this.details = data.details;
        this.action = data.action;
        this.headingColor = data.headingColor;
        this.color = data.color;
        this.bgcolor = data.bgcolor;
        this.headingBgcolor = data.headingBgcolor;
        this.image1Bg = data.image1Bg;
        this.layoutId = data.layoutId;
        this.sections = data.sections;
        this.imageUrl = data.imageUrl;
        this.thumbImageUrl = data.thumbImageUrl;
        this.originalImageUrl = data.originalImageUrl;
        this.awarded = data.awarded;
        this.favorited = data.favorited;
        this.redeemable = data.redeemable;
        this.respondable = data.respondable;
        this.respondableInfo = data.respondableInfo;
        this.extraJson = data.extraJson;
        this.businessValue = +(data.businessValue ?? 0);
        this.metricAmount = +(data.metricAmount ?? 0);
        this.metricName = data.metricName;
        this.targetUrl = data.targetUrl;
        this.internalUrl = data.internalUrl;
        this.addressRequired = data.addressRequired;
        this.trackingCode = data.trackingCode;
        this.responseMessage = data.responseMessage;
        this.acceptanceRequired = data.acceptanceRequired;
        this.acceptanceText = data.acceptanceText;
        this.acceptanceUrl = data.acceptanceUrl;
    }

    get expiresInDays() {
        const days = daysFromNow(this.endDate);
        return this.endDate && days > 0 ? days : undefined;
    }
}

export class CheetahResponse {
    id: string;
    respondable: boolean;
    processingStatus: string;
    usageStartDate: string;
    usageEndDate: string;
    coupon: {
        barcode: string;
    };
    offer?: CheetahReward;
    reward?: CheetahReward;

    constructor(data: CheetahResponse) {
        this.id = data.id;
        this.respondable = data.respondable;
        this.processingStatus = data.processingStatus;
        this.usageStartDate = data.usageStartDate;
        this.usageStartDate = data.usageStartDate ? DateTime.fromISO(data.usageStartDate) : undefined;
        this.usageEndDate = data.usageEndDate ? DateTime.fromISO(data.usageEndDate).minus({ days: 1 }) : undefined;
        this.coupon = data.coupon;
        //@ts-ignore;
        this.offer = new CheetahReward(data.offer ?? data.reward);
    }

    get phoenixCode(): string {
        return this.coupon.barcode;
    }
    get loyaltyOfferCode() {
        return this.id;
    }

    get asCouponRequest() {
        return { earnedOfferId: this.id, phoenixCode: this.phoenixCode, loyaltyOfferCode: this.loyaltyOfferCode, name: this.offer?.heading };
    }

    get expiresInDays() {
        const days = daysFromNow(this.usageEndDate);
        const daysUntilExpiration = Math.max(days, 0);
        return this.usageEndDate && daysUntilExpiration >= 0 ? daysUntilExpiration : undefined;
    }
}

export class CouponRequest {}

export class CheetahMemberSso {
    memberId?: string;
    integrationId?: string;
    accessToken?: string;
    refreshToken?: string;
    createdAt?: number; //1659968978
    expiresIn?: number; //7123,
    code?: number;
    message?: string;

    constructor(data: CheetahMemberSso) {
        if (!data) return;

        this.memberId = data.memberId;
        this.integrationId = data.integrationId;
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
        this.createdAt = data.createdAt;
        this.expiresIn = data.expiresIn;

        this.code = data?.code;
        this.message = data?.message;
    }

    get expiration() {
        if (!this.createdAt || !this.expiresIn) return false;
        return DateTime.fromISO(new Date((this.createdAt + this.expiresIn) * 1000).toUTCString());
    }
    get hasExpired() {
        return this.expiration <= DateTime.now();
    }
    get asStellarMemberState() {
        if (!this.accessToken || this.hasExpired) return false;
        return `var stellar_member_state = {
      access_token: "${this.accessToken}",
      refresh_token: "${this.refreshToken}",
      sso_error: "", // non-empty value indicates an error
      short_name: "",
      full_name: "",
    }`;
    }
}

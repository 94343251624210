import store from '@/store';
import ApiHelper from '@/services/ApiHelper';
import { CustomerAddress, DeleteAddress } from '@/models/addressModels';
import { Account, AccountLoyaltyInfo, LoginReq } from '@/models/account/authModels';
import UserProfile from '@/models/account/userProfile';
import { CreditCard, DeleteCard } from '@/models/PaymentModels';
import LocationManager from '@/utils/locationManager';
import { useGoogleAnalytics } from '@/composables/useGoogleAnalytics';

export function addressIconType(type: string) {
    switch (type.toLowerCase().trim()) {
        case 'house':
            return 'mdi-home';
        case 'business':
            return 'mdi-domain';
        case 'campus/base':
            return 'mdi-home-city';
        case 'hotel/hospital':
            return 'mdi-hospital-building';
        default:
            return 'mdi-home';
    }
}

export const addAccountAddressPost = (address: CustomerAddress): Promise<{ addressId: string }> => {
    // api knows "0" means it's a new address
    address.addressId = '0';
    return ApiHelper.post(`/adddeliveryaddress`, address);
};
export const editAccountAddressPost = (address: CustomerAddress): Promise<{ addressId: string }> => ApiHelper.post(`/editdeliveryaddress`, address);
export const deleteAddress = (address: DeleteAddress): Promise<any> => ApiHelper.post(`/deletedeliveryaddress`, address);
export const deleteAccount = (customerId: string): Promise<any> => ApiHelper.post(`/deletecustomer`, { customerId: customerId });

export const fetchStoredCards = (customerId: string): Promise<CreditCard[]> => {
    return ApiHelper.get(`/liststoredcards?customerId=${customerId}`)
        .then((res) => Promise.resolve(res.map((card) => new CreditCard(card))))
        .catch((error) => Promise.reject(error));
};
export const addCreditCard = (card: CreditCard): Promise<{ creditCardID: string }> => {
    return ApiHelper.post(`/addstoredcard`, card.accountPayload())
        .then((res) => Promise.resolve(res))
        .catch((error) => Promise.reject(error));
};
export const updateStoredCard = (card: CreditCard): Promise<{ creditCardId: string }> => {
    return ApiHelper.post(`/editstoredcard`, card.accountPayload())
        .then((res) => Promise.resolve(res))
        .catch((error) => Promise.reject(error));
};
export const deleteStoredCard = (card: DeleteCard): Promise<{ creditCardId: string }> => {
    return ApiHelper.post(`/deletestoredcard`, card)
        .then((res) => Promise.resolve(res))
        .catch((error) => Promise.reject(error));
};

/**
 * If a customer does not exist with the given tokenId email, a new customer will be created as a side effect
 * @param getFullProfile number (1 or 0)
 * @param includeCheetah number (1 or 0)
 * @returns Account
 */
export const fetchAccountInfo = (getFullProfile = 1, includeCheetah = 1): Promise<Account> => {
    const { trackUserId } = useGoogleAnalytics();

    return new Promise((resolve, reject) =>
        ApiHelper.get(`/getprofile?getFullProfile=${getFullProfile}&includeCheetah=${includeCheetah}`)
            .then((res) => {
                if (res instanceof Array && res[0] == 'not found') reject();
                if (res?.customerId) trackUserId(res.customerId);
                resolve(new Account(res));
            })
            .catch((err) => reject(err))
    );
};

export const fetchMemberResponses = (): Promise<AccountLoyaltyInfo> => {
    return new Promise((resolve, reject) =>
        ApiHelper.getLoyaltyApi(`/v0/loyalty`)
            .then((res) => {
                if (res instanceof Array && res[0] == 'not found') reject();
                resolve(new AccountLoyaltyInfo(res));
            })
            .catch((err) => reject(err))
    );
};

export const rewardsOptIn = (account: Account) => {
    const profile = account.userProfile;
    profile.loyaltyOptInCreateMember = 1;
    return updateAccountInfo(profile);
};
export const updateAccountInfo = (request: UserProfile) =>
    new Promise((resolve, reject) => {
        ApiHelper.post(`/manageprofile`, request)
            .then((response) => {
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
    });

export const updateCommunicationPreferences = (request) =>
    new Promise((resolve, reject) => {
        ApiHelper.post(`/updatecommunicationpreferences`, request)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });

export const loginRequest = (loginRequest: LoginReq): Promise<Account> => {
    return ApiHelper.post(`/login`, loginRequest)
        .then((res) => Promise.resolve(new Account(res)))
        .catch((error) => Promise.reject(error));
};

export const fetchPaymentGatewayToken = (recaptchaToken: string): Promise<string> => {
    const locationIdUrl = store.getters.getSelectedLocation ? store.getters.getSelectedLocation.id : LocationManager.getInstance().getLocationId();
    return ApiHelper.get(`/elavontoken?locationId=${locationIdUrl}&recaptchaToken=${recaptchaToken}`)
        .then((res) => Promise.resolve(res))
        .catch((error) => Promise.reject(error));
};

export const verifyEmail = (code: string): Promise<any> => {
    return ApiHelper.get(`/verifyemail?code=${code}`)
        .then((res) => Promise.resolve(res))
        .catch((error) => Promise.reject(error));
};

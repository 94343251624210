import { reactive } from 'vue';
import { DEFAULT_LOCATION_ID } from '@/constants';
import { Geolocation } from '@capacitor/geolocation';
import ApiHelper from '@/services/ApiHelper';

class LocationManager {
    private static instance: LocationManager;
    private state = reactive({
        locationId: DEFAULT_LOCATION_ID,
    });

    private constructor() {
        this.initialize();
    }

    public static getInstance(): LocationManager {
        if (!LocationManager.instance) {
            LocationManager.instance = new LocationManager();
        }
        return LocationManager.instance;
    }

    private async initialize() {
        try {
            const position = await Geolocation.getCurrentPosition({ timeout: 30000 });
            const coords = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            };

            const response = await ApiHelper.post(`/findstore/${coords.latitude}/${coords.longitude}`);

            if (response && response.id) {
                this.state.locationId = response.id;
            }
        } catch (error) {
            console.error('Error getting geolocation', error);
        }
    }

    public getLocationId(): string {
        return this.state.locationId;
    }

    public setLocationId(newLocationId: string): void {
        this.state.locationId = newLocationId;
    }
}

export default LocationManager;

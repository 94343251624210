<template>
    <ion-row>
        <ion-col v-if="!history || typeof history === 'string'" class="ion-text-center">
            <p>It looks like you have not ordered anything yet.. Let's change that!</p>
            <ion-button @click="emit('close')" router-link="/menu">Start my order</ion-button>
        </ion-col>

        <ion-item v-else v-for="order in history" :key="order.order" class="full-width" lines="full">
            <ion-label class="full-width ion-text-wrap">
                <ion-row class="ion-align-items-center">
                    <!-- Mobile view -->
                    <ion-col v-if="isMobile" class="py-0" :size="addOrderBtn ? 8 : 12" :size-md="addOrderBtn ? 9 : 12">
                        <p class="mt-0 mb-4">{{ order.displayDate }}</p>
                        <inlineList :items="order.items" keyName="itemName" data-cy="order-line-items" class="my-0 normal ion-text-wrap" :displayFunc="inlineOrderHistory" />
                    </ion-col>
                    <!-- Desktop view -->
                    <ion-col v-if="!isMobile" class="py-0">
                        <p class="mt-0 mb-4 pl-8">{{ order.displayDate }}</p>
                    </ion-col>
                    <ion-col v-if="!isMobile" class="py-0 text-center">
                        <inlineList :items="order.items" keyName="itemName" data-cy="order-line-items" class="my-0 normal ion-text-wrap font-18" :displayFunc="inlineOrderHistory" />
                    </ion-col>
                    <ion-col class="py-0 ion-text-end" v-if="addOrderBtn">
                        <ion-button fill="clear" @click="addToCart(order)">Add to cart</ion-button>
                    </ion-col>
                </ion-row>
            </ion-label>
        </ion-item>
        <ion-col size="12" class="ion-text-center">
            <contentBlock slug="order-history-100" />
        </ion-col>
    </ion-row>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { IonRow, IonCol, IonButton, IonItem, IonLabel } from '@ionic/vue';
import contentBlock from '@/components/contentBlock.vue';
import { Account } from '@/models/account/authModels';
import { inlineOrderHistory } from '@/services/orderService';
import inlineList from '@/components/inlineList.vue';
import { HistoryOrder } from '@/models/cartModels';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
    emits: ['close'],
    props: { account: { type: Object as PropType<Account>, required: true }, addOrderBtn: Boolean },
    components: { contentBlock, IonRow, IonCol, IonButton, IonItem, IonLabel, inlineList },
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const history = computed((): HistoryOrder[] => store.getters.getOrderHistory);
        const isMobile = computed(() => store.getters.isMobile);
        const addToCart = (order: HistoryOrder) => {
            emit('close');
            store.dispatch('addOrderToCart', order).then(() => router.push('/cart'));
        };

        return { history, addToCart, inlineOrderHistory, emit, isMobile };
    },
});
</script>

<style scoped>
.font-18 {
    font-size: 18px;
}
</style>

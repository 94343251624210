import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { slot: "content" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_accountDetail = _resolveComponent("accountDetail")!
  const _component_savedCards = _resolveComponent("savedCards")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_addressList = _resolveComponent("addressList")!
  const _component_communicationPref = _resolveComponent("communicationPref")!
  const _component_orderHistory = _resolveComponent("orderHistory")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_modalWrapper = _resolveComponent("modalWrapper")!
  const _component_confirmationModal = _resolveComponent("confirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.justModal)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({ wait: _ctx.isLoginProcessing })
        }, [
          (_ctx.isLoggedIn && !_ctx.iconBtn)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 0,
                button: "",
                lines: "none",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalOpen = true)),
                class: "bold view-account"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" View Account ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : (_ctx.isLoggedIn)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalOpen = true)),
                  fill: "clear"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: require('@/assets/account-loggedin-icon.svg'),
                      color: "medium",
                      class: "mb-4",
                      style: {"font-size":"30px"},
                      "data-cy": "desktop-account-icon"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_button, {
                  key: 2,
                  fill: "clear",
                  disabled: _ctx.isLoginProcessing
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/login?redirect=${_ctx.$route.path}`
                    }, {
                      default: _withCtx(() => [
                        (_ctx.iconBtn)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: require('@/assets/account-icon.svg'),
                              color: "medium",
                              style: {"font-size":"25px"},
                              "data-cy": "desktop-account-icon"
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _: 1
                }, 8, ["disabled"]))
        ], 2))
      : _createCommentVNode("", true),
    (!!_ctx.account)
      ? (_openBlock(), _createBlock(_component_modalWrapper, {
          key: 1,
          class: "modal-wrapper",
          title: "My Account",
          maxWidth: "600px",
          open: _ctx.modalOpen,
          onClose: _ctx.close,
          onDismiss: _ctx.close
        }, {
          default: _withCtx(() => [
            _createVNode(_component_accountDetail, { account: _ctx.account }, null, 8, ["account"]),
            _createVNode(_component_ion_accordion_group, {
              style: _normalizeStyle(_ctx.contentStyle),
              onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.accordionGroupChange($event))),
              ref: "accordionGroup"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_accordion, { value: "payment" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      slot: "header",
                      button: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("Payment Information")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { slot: "content" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_savedCards, { account: _ctx.account }, null, 8, ["account"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_accordion, { value: "addresses" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      slot: "header",
                      button: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("Saved Addresses")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_addressList, {
                      slot: "content",
                      addresses: _ctx.account?.deliveryAddresses,
                      editable: "",
                      deleteable: "",
                      addBtn: ""
                    }, null, 8, ["addresses"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_accordion, { value: "communication" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      slot: "header",
                      button: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("Communication Preferences")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_communicationPref, {
                      slot: "content",
                      account: _ctx.account
                    }, null, 8, ["account"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_accordion, { value: "history" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      slot: "header",
                      button: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("History")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("span", _hoisted_1, [
                      _createVNode(_component_orderHistory, {
                        account: _ctx.account,
                        onClose: _ctx.close,
                        addOrderBtn: ""
                      }, null, 8, ["account", "onClose"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["style"])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_ion_row, { class: "ion-justify-content-center ion-align-items-center account-footer ion-padding-bottom" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toolbarLinks, (link) => {
                  return (_openBlock(), _createBlock(_component_ion_col, {
                    key: link.name,
                    class: "ion-text-center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        class: "toolbar-link bold",
                        onClick: 
                            () => {
                                link.click();
                            }
                        ,
                        size: "small",
                        fill: "clear"
                      }, _toDisplayString(link.name), 9, _hoisted_2)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open", "onClose", "onDismiss"]))
      : _createCommentVNode("", true),
    _createVNode(_component_confirmationModal, {
      title: "Delete Account",
      header: "Are you sure you want to delete your account?",
      message: "Deleting your account will erase all your data, including points and any unused rewards. This can't be undone, hon—so please choose wisely.",
      confirmationMessage: "Delete My Account",
      declineMessage: "Nevermind!",
      modalVisible: _ctx.showDeleteAccountConfirmation,
      onAccept: _ctx.acceptDeleteAccount,
      onDecline: _ctx.declineDeleteAccount,
      close: _ctx.closeDeleteAccountConfirmation
    }, null, 8, ["modalVisible", "onAccept", "onDecline", "close"])
  ], 64))
}
import { exit } from 'ionicons/icons';

export const OrderType = Object.freeze([
    // At Store
    { name: 'Pick Up', display: 'Pick Up Inside', icon: require('@/assets/pick_up_icon.svg'), isDelivery: false, logo: 'counterPickupPep.png' },
    { name: 'Curbside', display: 'Curbside', icon: exit, isDelivery: false, logo: 'curbsidePickupPep.png' },
    { name: 'Window', display: 'Pick-Up Window', icon: require('@/assets/window_icon.svg'), isDelivery: false, logo: 'windowPickupPep.png' },
    { name: 'Dine In', display: 'Eat In', icon: require('@/assets/eat_in_icon.svg'), isDelivery: false, logo: 'superHappyPep.png' },
    { name: 'Community Room', display: 'Community Room', icon: exit, isDelivery: false, logo: 'superHappyPep.png' },
    // Delivery
    { name: 'Delivery', display: 'Delivery', icon: require('@/assets/delivery_icon.svg'), isDelivery: true, logo: 'deliveryPep.png' },
]);

export enum OrderTypeEnum {
    pickup = 'Pick up',
    delivery = 'Delivery',
}

export const OrderTypeSortOrder = Object.freeze(['Window', 'Curbside', 'Pick Up', 'Dine In', 'Community Room', 'Delivery']);

<script>
import { defineComponent, h } from 'vue';
import { IonSkeletonText, IonThumbnail } from '@ionic/vue';

export default defineComponent({
    props: {
        element: String,
        childElements: Array,
        animate: Boolean,
    },
    render() {
        return [
            h(this.element ?? 'span', [
                this.childElements.map((e) =>
                    e.image
                        ? h(IonThumbnail, () => [h(IonSkeletonText)])
                        : h(IonSkeletonText, {
                              style: `width:${e.width}%;display:${e.display ?? 'block'};margin-right:${e.marginRight};height:${e.height ?? '10px'};background:${e.background};`,
                              animated: this.animate,
                              class: e.class,
                          })
                ),
            ]),
        ];
    },
});
</script>

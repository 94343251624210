import { DeviceSecurityType, VaultType } from '@ionic-enterprise/identity-vault';
import { Device } from '@ionic-enterprise/identity-vault';
import { BrowserVault, IdentityVaultConfig, Vault } from '@ionic-enterprise/identity-vault';
import { Capacitor } from '@capacitor/core';
import { ref } from 'vue';

export type UnlockMode = 'Device' | 'SystemPIN' | 'SessionPIN' | 'NeverLock' | 'ForceLogin';
// Splash screen on background prevents iOS login.
Device.setHideScreenOnBackground(false, false);

//
// START Create Vault
const config: IdentityVaultConfig = {
    key: 'com.donatos-pizza.donatos',
    type: VaultType.SecureStorage,
    deviceSecurityType: DeviceSecurityType.None,
    lockAfterBackgrounded: 300000, // 5min
    shouldClearVaultAfterTooManyFailedAttempts: true,
    customPasscodeInvalidUnlockAttempts: 2,
    unlockVaultOnLoad: false,
};
const vault = Capacitor.getPlatform() === 'web' ? new BrowserVault(config) : new Vault(config);
// END Create Vault

// Fallback config to ensure vault is never null or undefined
if (!vault.config) {
    await vault.updateConfig(config);
}

// Session
const key = 'sessionData';
const session = ref<string | null | undefined>();

const setSession = async (value: string): Promise<void> => {
    session.value = value;
    await vault.setValue(key, value);
};
const restoreSession = async () => {
    const value = await vault.getValue(key);
    session.value = value;
};
const clearVault = async (): Promise<void> => {
    await vault.clear();
    await setUnlockMode('NeverLock');
    session.value = undefined;
};

//
// START Vault Lock & Unlock methods
const vaultIsLocked = ref(false);
const lockVault = () => {
    vault.lock();
};
const unlockVault = async () => {
    await vault
        .unlock()
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err));
};
vault.onLock(() => {
    vaultIsLocked.value = true;
    session.value = undefined;
});

// Do not nest authService methods
// authService unlocks the vault to read data out of it. will cause loop.
vault.onUnlock(() => {
    vaultIsLocked.value = false;
});

const initializeUnlockMode = async () => {
    if (Capacitor.getPlatform() === 'web') return await setUnlockMode('NeverLock');
    if (await Device.isSystemPasscodeSet()) {
        if (await Device.isBiometricsEnabled()) {
            return await setUnlockMode('Device');
        } else {
            return await setUnlockMode('Device');
        }
    } else {
        return await setUnlockMode('SessionPIN');
    }
};
const canUnlock = async (): Promise<boolean> => !(await vault.isEmpty()) && (await vault.isLocked());

const setUnlockMode = async (unlockMode: UnlockMode): Promise<void> => {
    let type: VaultType;
    let deviceSecurityType: DeviceSecurityType;

    switch (unlockMode) {
        case 'Device':
            type = VaultType.DeviceSecurity;
            deviceSecurityType = DeviceSecurityType.Both;
            break;

        case 'SessionPIN':
            type = VaultType.CustomPasscode;
            deviceSecurityType = DeviceSecurityType.None;
            break;

        case 'ForceLogin':
            type = VaultType.InMemory;
            deviceSecurityType = DeviceSecurityType.None;
            break;

        case 'NeverLock':
            type = VaultType.SecureStorage;
            deviceSecurityType = DeviceSecurityType.None;
            break;

        default:
            type = VaultType.SecureStorage;
            deviceSecurityType = DeviceSecurityType.None;
    }

    let vaultKey = 'com.donatos-pizza.donatos';
    if (vault.config) {
        vaultKey = vault.config.key;
    }

    await vault.updateConfig({
        ...vault.config,
        key: vaultKey,
        type,
        deviceSecurityType,
    });
};
// END Vault Lock & Unlock methods

export default (): any => {
    return {
        vault,

        session,
        setSession,
        restoreSession,
        clearVault,

        setUnlockMode,
        initializeUnlockMode,
        canUnlock,
        vaultIsLocked,
        lockVault,
        unlockVault,
    };
};

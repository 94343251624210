export enum ThemeEnum {
    none = 'none',
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    light = 'light',
    medium = 'medium',
    dark = 'dark',
    lightDanger = 'light-danger',
    lightWarning = 'light-warning',
    lightSuccess = 'light-success',
}

import { stringToBool } from '@/services/stringService';

export default class CommunicationPreferences {
    customerId: string;
    receiveDonatosNewsAndEventEmails: boolean;
    ltdCommunicationEmail: boolean;
    lrmEmails: boolean;
    receivePersonalizedOffers: boolean;
    receiveSmsOffers: boolean;
    receiveMobileAppOffers: boolean;

    constructor(data?: Partial<CommunicationPreferences>) {
        this.customerId = data?.customerId ?? '';
        this.receiveDonatosNewsAndEventEmails = stringToBool(data?.receiveDonatosNewsAndEventEmails) ?? false;
        this.ltdCommunicationEmail = stringToBool(data?.ltdCommunicationEmail) ?? false;
        this.lrmEmails = stringToBool(data?.lrmEmails) ?? false;
        this.receivePersonalizedOffers = stringToBool(data?.receivePersonalizedOffers) ?? false;
        this.receiveSmsOffers = stringToBool(data?.receiveSmsOffers) ?? false;
        this.receiveMobileAppOffers = stringToBool(data?.receiveMobileAppOffers) ?? false;
    }
}

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["innerHTML", "id"]
const _hoisted_2 = ["id", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.canDisplayBlock)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: $setup.selectedBlock.content,
          id: $props.slug
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!$setup.canDisplayBlock && $props.backupText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: $props.slug,
          innerHTML: $props.backupText
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (!$setup.canDisplayBlock && $props.backupSlot)
      ? _renderSlot(_ctx.$slots, "default", { key: 2 })
      : _createCommentVNode("", true)
  ]))
}
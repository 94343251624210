import { HistoryOrderItem } from './../models/cartModels';
import { DateTime } from 'luxon';
import { FullOrder, OrderConfirmation, ItemPriceCal, OrderLogistics, HistoryOrder } from '@/models/cartModels';
import { ToppingPrice } from './../models/menuModels';
import ApiHelper from '@/services/ApiHelper';
import { AvailableTopping, Menu, Topping, ToppingReq, Instruction, MenuItem, Size, Recipe, MenuCategory } from '@/models/menuModels';
import { objectToQueryString, phoenixError, removeNumbersFromErr } from './stringService';
import { LineItem, AddToCartRes, Coupon } from '@/models/cartModels';
import { GiftCard, validGiftCardResponse } from '@/models/PaymentModels';
import { useGoogleAnalytics } from '@/composables/useGoogleAnalytics';

export const lineItemMapper = (menuItem: MenuItem, instruction: Instruction[], quantity: number, availableTopping: AvailableTopping, crust?: Recipe, size?: Size, menuItem2?: MenuItem) => {
    //@ts-ignore
    return new LineItem({
        customerId: undefined,
        quantity: quantity,
        id: menuItem?.itemExternalId,
        name: menuItem?.name,
        baseprice: menuItem?.itemPrice,
        imageSm: menuItem?.itemImage,
        price: size?.price ?? menuItem?.itemPrice,
        selectedInstructions: instruction,
        recipes: crust?.recipeLineItem(!size ? [] : size instanceof Array ? size : [size]) ?? [],
        toppings: availableTopping.toppings,
        selectedToppings: removeDuplicateToppings(availableTopping.updatingSelectedToppings ?? availableTopping.toppings?.filter((t) => t.selected)),
        selectedDressings: availableTopping.dressings,
        selectedFlavors: availableTopping.flavors,
        selectedSauces: [],
        selectedRequiredToppings: (crust?.isHandTossed || crust?.isStuffedCrust || crust?.isBakery) && availableTopping?.dressings.length > 0 ? availableTopping?.dressings : [],
        selectedRequiredToppings2: (crust?.isHandTossed || crust?.isStuffedCrust || crust?.isBakery) && availableTopping?.dressings.length > 0 ? availableTopping?.dressings : [],

        id2: menuItem2?.itemExternalId,
        name2: menuItem2?.name,
        baseprice2: !menuItem2 ? undefined : menuItem2?.itemPrice,
        imageSm2: menuItem2?.itemImage,
        price2: !menuItem2 ? undefined : menuItem2?.itemPrice ?? size?.price,
        selectedInstructions2: !menuItem2 ? undefined : instruction,
        recipes2: !menuItem2 ? undefined : crust?.recipeLineItem(!size ? [] : size instanceof Array ? size : [size]),
        toppings2: !menuItem2 ? undefined : availableTopping.toppings2 ?? availableTopping.toppings,
        selectedToppings2: availableTopping.updatingSelectedToppings2 ?? availableTopping.toppings2?.filter((t) => t.selected),
        selectedDressings2: availableTopping.dressings2,
        selectedFlavors2: availableTopping.flavors2,
        selectedSauces2: [],
    });
};
export const simpleLineItemMapper = (menuItem: MenuItem, quantity: number, availableTopping: AvailableTopping) => {
    //@ts-ignore
    return new LineItem({
        customerId: undefined,
        quantity: quantity,
        id: menuItem?.itemExternalId,
        name: menuItem?.name,
        baseprice: menuItem?.itemPrice,
        imageSm: menuItem?.itemImage,
        price: menuItem.itemPrice,
        selectedInstructions: [],
        recipes: menuItem.firstRecipe?.recipeLineItem([menuItem.firstSize]),
        toppings: availableTopping.toppings,
        selectedToppings: removeDuplicateToppings(availableTopping.updatingSelectedToppings ?? availableTopping.toppings?.filter((t) => t.selected)),
        selectedDressings: availableTopping.dressings,
        selectedFlavors: availableTopping.flavors,
        selectedSauces: [],
    });
};

const findMenuItem = (lineItem: LineItem, items: MenuItem[]) => items.find((i) => i.itemExternalId == lineItem.id || i.itemDisplayName == lineItem.name);
export const inlineToppingDisplay = (item) => {
    const prepend = item.multiplier > 1 ? `xtra ` : !item.selected ? 'No ' : '';
    return prepend + item.name;
};
export const inlineOrderHistory = (item: HistoryOrderItem) => {
    return `<b>${item.itemName}</b> <span class="normal x-small">(${item.size}${!item.crusttype?.includes('all') ? ' | ' + item.crusttype : ''})</span>`;
};
/** Used to convert a cart item back into a menu item to be used on the menuItemPage for editing */
export const lineItemToMenuItemMapper = (lineItem: LineItem, menu: MenuCategory[]): MenuItem | undefined => {
    if (!lineItem) return;
    let foundMenuItem: MenuItem | undefined;
    menu.every((category) => {
        foundMenuItem = lineItem.name2 ? category.items.find((c) => c.isCustomPizza) : findMenuItem(lineItem, category.items);
        return !foundMenuItem; // every() only continues loop on truthy return
    });
    if (!foundMenuItem) return;
    foundMenuItem = foundMenuItem.deepCopyItem;
    if (!lineItem.recipes) return foundMenuItem;
    // preselect recipe & size
    foundMenuItem.recipes.forEach((newRecipe) => {
        if (lineItem.recipes instanceof Array) {
            const lineItemRecipe = lineItem.recipes?.find((lir) => lir.id == newRecipe.externalId);
            newRecipe.preselected = !!lineItemRecipe;
            if (!newRecipe.preselected) return;
            newRecipe.sizes.forEach((s) => {
                s.preselected = lineItemRecipe?.size.some((lis) => lis.id == s.externalId);
            });
        }
    });

    return foundMenuItem;
};

export const cartItemTotal = (items: LineItem[]): number => +items?.reduce((total, item) => (total += +item.price * item.quantity), 0);
export const calcCalorieRange = (size: Size, toppings: Topping[]) => {
    const range = toppings
        ?.filter((t) => t.selected)
        .map((t) =>
            t.calories
                ?.match(/[0-9]*/g)
                ?.filter((n) => !!n)
                .map((str) => (size.calorieUnit?.includes('pc') ? (+str * t.multiplier) / size.slices : +str * t.multiplier))
        )
        ?.filter((r) => !!r)
        .reduce(
            (t: [number, number], r) => {
                t[0] += r ? r[0] : 0;
                t[1] += r ? r[1] ?? r[0] : 0;
                return t;
            },
            [size.calorieValue, size.calorieValue]
        );
    return !range ? 0 : range[0] == range[1] ? range[0] : `${range[0].toFixed(0)}-${range[1].toFixed(0)}`;
};

//#region Menu Item Price calculation
export const filterPricesBySize = (recipe, size, toppingPrices) =>
    toppingPrices?.filter((p) => (p.recipePid === recipe?.recipeId || p.recipePid == '-1') && (p.sizePid === size?.externalId || p.sizePid == '-1'));

export const matchToppingToPrice = (topping: Topping, toppingPrices: ToppingPrice[]) => {
    // Inefficient but this needs to prioritize a matching id first. If none match, fallback on the wildcard '-1' id
    let matches = toppingPrices.find((p) => p.toppingPid.some((tp) => tp === topping?.toppingPid));
    if (!matches) matches = toppingPrices.find((p) => p.toppingPid.some((tp) => tp === '-1'));
    return matches;
};
/* Must already have filtered down based on category, recipe, and size */
export const sumSelectedToppings = (toppings: Topping[], toppingPrices: ToppingPrice[]): number => {
    return toppings
        ?.filter((t) => t?.selected)
        .reduce((total, topping) => {
            const matches = matchToppingToPrice(topping, toppingPrices);
            const multiply = topping.isDefault && topping?.multiplier == 1 ? 0 : topping?.multiplier;
            return +(total += matches ? matches?.toppingprice * multiply : 0)?.toFixed(2);
        }, 0);
};
/**
 * @function sumToppingPriceBySelectedSize
 * @param {Recipe} recipe
 * @param {Size} size
 * @param {ToppingPrice[]} toppingPrices
 * @param {Topping[]} toppings
 * @param {boolean} includeBasePrice
 * @returns number
 */
export const sumToppingPriceBySelectedSize = (recipe?: Recipe, size?: Size, toppingPrices?: ToppingPrice[], toppings?: Topping[], includeBasePrice = true) => {
    if (!recipe || !size || !toppingPrices || !toppings) return 0;
    return +(sumSelectedToppings(toppings, filterPricesBySize(recipe, size, toppingPrices)) + (includeBasePrice ? size.price : 0)).toFixed(2);
};
//#endregion

/**
 * Increase `multiplier` and set `selected` for a random dressing in Topping[]
 * @param dressings
 * @param dressingCount
 * @param noToppingIndex - the index of the "No Dressing" option
 */
export const incrementRandomDressing = (dressings: Topping[], dressingCount: number, noToppingIndex: number) => {
    const randomIndex = Math.floor(Math.random() * dressingCount);
    if (randomIndex == noToppingIndex && dressings.length > 1) return incrementRandomDressing(dressings, dressingCount, noToppingIndex);
    else {
        dressings[randomIndex].multiplier += 1;
        dressings[randomIndex].selected = true;
    }
};

/**
 * Resets and selects multiple dressings with varying multipliers
 * @param dressings
 * @param preference - the "Default" dressing for a salad (Italian Garden == Italian)
 */
export const dressingVariety = (dressings: Topping[], preference?: Topping) => {
    const dressingCount = dressings.filter((d) => !d.isNoTopping).length;
    const noToppingIndex = dressings.findIndex((d) => d.isNoTopping);
    let numberToChange = 10;

    // set preferred dressing as selected with 5x multiplier
    if (preference) {
        const mainDressing = dressings.find((d) => d.toppingPid == preference.toppingPid);
        if (mainDressing) {
            mainDressing.multiplier = 5;
            mainDressing.selected = true;
            numberToChange = numberToChange - 5;
        }
    }

    for (let i = 0; i < numberToChange; i++) {
        incrementRandomDressing(dressings, dressingCount, noToppingIndex);
    }
};

export const buildOrderConfirmation = (requestOrder: FullOrder, orderResponse: OrderConfirmation): FullOrder => {
    requestOrder.orderHeaderID = orderResponse.orderHeaderID;
    requestOrder.orderHeader.submitResult.orderHeaderID = orderResponse.orderHeaderID;
    requestOrder.orderHeader.submitResult.promiseTime = DateTime.fromISO(`${requestOrder.logistics.date}T${requestOrder.logistics.time}`).plus({
        minutes: orderResponse?.promiseTime,
    });
    return requestOrder;
};

export const removeDuplicateToppings = (toppings: Topping[]) => toppings?.filter((value, index, self) => index === self.findIndex((t) => t.toppingPid === value.toppingPid));

/** API */
export const fetchMenu = (id: string): Promise<Menu> => {
    return new Promise((resolve, reject) => {
        if (!id || id == '0') return reject();
        ApiHelper.get(`/menu?locationId=${id}`)
            .then((res) => resolve(new Menu(res)))
            .catch((error) => reject(error));
    });
};
let fetchingToppings = false;
export const fetchToppings = (queryObj: ToppingReq): Promise<AvailableTopping> => {
    if (fetchingToppings) return Promise.reject();
    fetchingToppings = true;
    return new Promise((resolve, reject) => {
        ApiHelper.get(`/menutoppings?${objectToQueryString(queryObj)}`)
            .then((res) => resolve(new AvailableTopping(res)))
            .catch((error) => reject(error))
            .finally(() => (fetchingToppings = false));
    });
};
export const fetchCart = (customerId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        ApiHelper.get(`/getcart?customerId=${customerId}`)
            .then((res) => {
                if (res instanceof Array && res[0] === 'not found') return reject();
                resolve(res);
            })
            .catch((error) => reject(error));
    });
};

export const addToCart = (cartItem: LineItem, accountId = undefined): Promise<AddToCartRes> => {
    if (accountId) cartItem.customerId = accountId;
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/addcartitem`, cartItem)
            .then((res) => resolve(new AddToCartRes({ ...res.orderDetails, promiseTime: res.orderHeader.submitResult.promiseTime.date })))
            .catch((error) => reject(error));
    });
};
/**
 * Add repeat order to cart using orderHeaderId & customerId
 * @param orderHeaderId string
 * @param customerId string
 * @returns AddToCartRes
 */
export const addOrderToCart = (orderHeaderId: string, customerId: string): Promise<AddToCartRes> => {
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/addcartitem`, { customerId: customerId, orderHeaderId: orderHeaderId })
            .then((res) => resolve(new AddToCartRes({ ...res.orderDetails, promiseTime: res.orderHeader.submitResult.promiseTime.date })))
            .catch((error) => reject(error));
    });
};
export const updateCartItem = (cartItem: LineItem): Promise<AddToCartRes> => {
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/updatecartitem`, cartItem)
            .then((res) => resolve(new AddToCartRes({ ...res.orderDetails, promiseTime: res.orderHeader.submitResult.promiseTime.date })))
            .catch((error) => reject(error));
    });
};
export const deleteFromCart = (cartItem: LineItem): Promise<AddToCartRes> => {
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/deletecartitem`, {
            customerId: cartItem.customerId,
            lineitemId: cartItem.lineitemId,
        })
            .then((res) => resolve(new AddToCartRes({ ...res.orderDetails, promiseTime: res.orderHeader.submitResult.promiseTime.date })))
            .catch((error) => reject(error));
    });
};
export const changeOrderDetails = (fullOrder: FullOrder): Promise<AddToCartRes | any> => {
    const request = !fullOrder.logistics.customerId ? fullOrder : new OrderLogistics(fullOrder.logistics);
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/changeorderdetails`, request)
            .then((res) => resolve(new AddToCartRes({ ...res.orderDetails, promiseTime: res.orderHeader.submitResult.promiseTime.date })))
            .catch((error) => reject(error));
    });
};

export const checkCouponCode = (code: string): Promise<Coupon> => {
    const { trackAddCoupon } = useGoogleAnalytics();
    return new Promise((resolve, reject) => {
        ApiHelper.get(`/checkcoupon?code=${code}`)
            .then((res) => {
                if (res.error) {
                    trackAddCoupon(code, false);
                    return reject(res);
                }
                trackAddCoupon(code, true);
                resolve(new Coupon(res));
            })
            .catch((error) => {
                trackAddCoupon(code, false);
                reject(error);
            });
    });
};
export const itemPrice = (item: LineItem): Promise<ItemPriceCal> => {
    return new Promise((resolve) =>
        ApiHelper.post(`/getitemprice`, item)
            .then((res) => resolve(new ItemPriceCal(res)))
            .catch(() => {
                resolve(new ItemPriceCal({ itemPrice: item.firstSize?.price.toString(), calories: '' }));
            })
    );
};
export const orderPrice = (order: FullOrder): Promise<FullOrder> => {
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/getorderprice`, order)
            .then((res) => {
                res instanceof Array && typeof res[0] === 'string' ? reject(removeNumbersFromErr(res[0])) : resolve(new FullOrder(res));
            })
            .catch((error) => reject(error));
    });
};

export const submitOrder = (order: FullOrder): Promise<OrderConfirmation> => {
    //@ts-ignore
    order.orderHeader.isMarketingEmail = (+order.orderHeader.isMarketingEmail).toString();
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/submitorder`, order)
            .then((res) => {
                res instanceof Array && typeof res[0] === 'string' ? reject(phoenixError(res[0])) : resolve(new OrderConfirmation(res));
            })
            .catch((error) => reject(phoenixError(error)));
    });
};

export const validateGiftcard = (giftCard: GiftCard): Promise<GiftCard> => {
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/validategiftcard`, giftCard.asValidationPayload)
            .then((res) => {
                res = new validGiftCardResponse(res);
                if (res.valid) {
                    giftCard.balance = res.balance;
                    resolve(giftCard);
                } else {
                    reject(res);
                }
            })
            .catch((error) => reject(error));
    });
};
export const fetchOrderDetail = (orderHeaderId: string): Promise<FullOrder> => {
    return new Promise((resolve, reject) => {
        ApiHelper.get(`/orderdetails?orderHeaderId=${orderHeaderId}`)
            .then((res) => {
                resolve(res);
                // resolve(new FullOrder(res));
            })
            .catch((error) => reject(error));
    });
};
export const replaceCart = (fullOrder: FullOrder): Promise<FullOrder> => {
    const customerId = fullOrder.orderHeader.customerId;
    fullOrder.orderDetails.lineItems.forEach((li) => (li.customerId = customerId));
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/replacecart`, fullOrder).then((order) => resolve(new FullOrder(order)));
    });
};
export const orderHistory = (customerId: string): Promise<HistoryOrder[]> => {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
        ApiHelper.post(`/orderhistory?customer_id=${customerId}`).then((history) => resolve(history.map((h) => new HistoryOrder(h))));
    });
};

import StaticPageModel from '../models/StaticPageModel';
import { ActionContext } from 'vuex';
import { Device, DeviceInfo } from '@capacitor/device';
import alertFactory, { alertEnum } from '@/services/factories/alertFactory';
import { fetchPage } from '@/services/pageService';
import { scrub } from '@/services/stringService';
import { MOBILE_BREAKPOINT } from '@/constants';
import router from '@/router';

export interface PageState {
    device?: DeviceInfo;
    staticPages: Map<string, StaticPageModel>;
    activeAlert: any;
    isCrawler: boolean;
}

const PageState: PageState = {
    device: undefined,
    staticPages: new Map(),
    activeAlert: null,
    isCrawler: false,
};

export default {
    state: PageState,
    mutations: {
        SET_DEVICE: (state: PageState, device: DeviceInfo): void => {
            state.device = device;
        },
        SET_CRAWLER: (state: PageState, isCrawler: boolean): void => {
            state.isCrawler = isCrawler;
        },
        ADD_STATIC_PAGE: (state: PageState, page: StaticPageModel): void => {
            state.staticPages.set(page.uri, page);
        },
        CLEAR_ALERT: (state: PageState): void => {
            state.activeAlert = null;
        },
        EMIT_ALERT: (state: PageState, alertEnum: alertEnum): void => {
            state.activeAlert = alertFactory(alertEnum);
        },
    },
    actions: {
        setDevice(context: ActionContext<PageState, any>): void {
            Device.getInfo().then((device) => context.commit('SET_DEVICE', device));
            //@ts-ignore
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            context.commit(
                'SET_CRAWLER',
                /bot|googlebot|crawler|spider|robot|crawling|linkedinbot|twitter|facebook|facebot|iaArchiver|bing|yahoo|duckduckbot|Screaming Frog|SEO|AhrefsBot|SemrushBot|Sitebulb|SEOMator|deepcrawl|OnCrawl|serpstatbot|dotbot|rogerbot|Prerender|Microsoft-BotFramework/i.test(
                    userAgent
                )
            );
        },
        addStaticPageContent(context: ActionContext<PageState, any>, uri: string): void {
            if (context.getters.getStaticPages.has(uri)) return;
            fetchPage(uri)
                .then((page) => {
                    //@ts-ignore
                    const arr: StaticPageModel[] = page instanceof Array ? page : Object.values(page);
                    // if a page isn't loaded from the api, redirect users to the 404
                    // because of this, do not use addStaticPageContent to load multiple pages into store
                    arr[0].uri == undefined && router.replace('/404');
                    arr.forEach((p) => {
                        if (typeof p == 'string') return;
                        p.content = scrub(page[0].content);
                        context.commit('ADD_STATIC_PAGE', p);
                    });
                })
                .catch(console.error);
        },
        clearAlert(context: ActionContext<PageState, any>): void {
            context.commit('CLEAR_ALERT');
        },
        alertClosedLocation(context: ActionContext<PageState, any>): void {
            // if the store is not currently open and still set to active: alert
            if (!context.getters.isStoreTakingOnlineOrders && !context.getters.isStoreInActive) context.commit('EMIT_ALERT', alertEnum.storeClosed);
        },
        alertInActiveLocation(context: ActionContext<PageState, any>): void {
            // if the store is set to not active: alert
            if (context.getters.isStoreInActive) context.commit('EMIT_ALERT', alertEnum.storeInActive);
        },
    },
    getters: {
        deviceInfo: (state: PageState): DeviceInfo | undefined => state.device,
        isMobile: (state: PageState): boolean =>
            state.device ? state.device.operatingSystem.includes('ios') || state.device.operatingSystem.includes('android') || window.innerWidth <= MOBILE_BREAKPOINT : false,
        isCrawler: (state: PageState): boolean => state.isCrawler,
        isPortraitOrientation: (state: PageState): boolean => (state.device ? window.innerHeight > window.innerWidth : false),
        isNative: (state: PageState): boolean => (state.device ? state.device.platform.includes('ios') || state.device.platform.includes('android') : false),
        getStaticPages: (state: PageState): Map<string, StaticPageModel> => state.staticPages,
        getStaticPage: (state: PageState) => (uri: string) => state.staticPages.get(uri),
        activePageAlert: (state: PageState): any => state.activeAlert,
        isIos: (state: PageState): boolean => (state.device ? state.device.operatingSystem.includes('ios') : false),
        isAndroid: (state: PageState): boolean => (state.device ? state.device.operatingSystem.includes('android') : false),
    },
};

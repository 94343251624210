<template>
    <div></div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    emits: [],
    props: {},
    components: {},
    setup() {
        const store = useStore();
        const isLoggedIn = computed(() => store.getters.isLoggedIn);
        let stellarInterval;

        const bindEvents = () => {
            const members = (window as any)?.Stellar?.member;
            const { summary, rewards } = members ?? {};

            if ((window as any)?.Stellar && members && (summary || rewards)) {
                store.dispatch('setStellarInfo', members);
                // THIS GETS TRIGGERED WHEN REWARDS ARE PURCHASED, ATTACH TO THIS FOR MEMEMBER-REPONSES REFRESH.
                (window as any)?.Stellar?.events?.bind('contentTokens.loaded', () => store.dispatch('refreshAccountRewardInfo'));
                setStellarInterval(false);
            }
        };

        const setStellarInterval = (v) => {
            if (v) {
                stellarInterval = setInterval(() => bindEvents(), 1000);
            } else {
                clearInterval(stellarInterval);
            }
        };

        watch(isLoggedIn, (v) => setStellarInterval(v));

        onMounted(() => {
            setStellarInterval(isLoggedIn.value);
        });

        onUnmounted(() => {
            setStellarInterval(false);
        });

        return {};
    },
});
</script>

import { Account } from '@/models/account/authModels';
import { CheetahResponse, CheetahReward } from '@/models/account/cheetahModels';

export const sortPointsAsc = (a: CheetahReward, b: CheetahReward) => a.points - b.points;
export const earnedOffers = (offers: CheetahResponse[]) => offers?.filter((o) => o.respondable) ?? [];
export const buyRewards = (balance: number, rewards: CheetahReward[]) => rewards?.filter((r) => r.points <= balance).sort(sortPointsAsc) ?? [];
export const allRewards = (balance: number, rewards: CheetahReward[]) => {
    const newRewards = rewards?.map((r) => r)?.sort(sortPointsAsc) ?? [];
    newRewards?.forEach((r) => {
        r.disabled = r.points > balance;
    });
    return newRewards;
};

export const nextReward = (balance: number, rewards: CheetahReward[]) => {
    const buyable = buyRewards(balance, rewards);
    if (buyable.length === 0) return;
    const next = buyable[0];
    next.diff = next.points - balance;
    return next;
};

export const rewardSummary = (account: Account) => {
    return {
        balance: account.currentBalance,
        earnedOffers: earnedOffers(account.cheetahMemberResponses),
        buyRewards: buyRewards(account.currentBalance, account.cheetahMemberRewards),
        nextReward: nextReward(account.currentBalance, account.cheetahMemberRewards),
        allRewards: allRewards(account.currentBalance, account.cheetahMemberRewards),
    };
};

export class ContentBlock {
    id: string;
    owner: string;
    description: string;
    slug: string;
    uri: string;
    rel: any | null;
    type: string;
    content: string;
    sort: string;
    status: string;
    created: string;
    createdBy?: string | null;
    updated: string;
    updatedBy: string;

    constructor(data?) {
        this.id = data?.id ?? '';
        this.owner = data?.owner ?? '';
        this.description = data?.description ?? '';
        this.slug = data?.slug ?? '';
        this.uri = data?.uri ?? '';
        this.type = data?.type ?? '';
        this.content = data?.content ?? '';
        this.sort = data?.sort ?? '';
        this.status = data?.status ?? '';
        this.created = data?.created ?? '';
        this.createdBy = data?.createdBy ?? '';
        this.updated = data?.updated ?? '';
        this.updatedBy = data?.updatedBy ?? '';
    }
}

export class HomePageBlock {
    id: string;
    name: string;
    firstBlock: string;
    secondBlock: string;
    backgroundImage: string;
    mobileBackgroundImage: string;
    weight: string;
    start: string;
    end: string;
    locationId: string;
    status: string;
    created: string;
    createdBy: string;
    updated: string;
    updatedBy: string;

    constructor(data?) {
        this.id = data?.id ?? '';
        this.name = data?.name ?? '';
        this.firstBlock = data?.firstBlock ?? '';
        this.secondBlock = data?.secondBlock ?? '';
        this.backgroundImage = data?.backgroundImage ?? '';
        this.mobileBackgroundImage = data?.mobileBackgroundImage ?? '';
        this.weight = data?.weight ?? '';
        this.start = data?.start ?? '';
        this.end = data?.end ?? '';
        this.locationId = data?.locationId ?? '';
        this.status = data?.status ?? '';
        this.created = data?.created ?? '';
        this.createdBy = data?.createdBy ?? '';
        this.updated = data?.updated ?? '';
        this.updatedBy = data?.updatedBy ?? '';
    }
}

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "ion-text-wrap sel-option" }
const _hoisted_2 = { class: "left-label" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toggle, {
                    "label-placement": "start",
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.dealsAndProductEmails,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dealsAndProductEmails) = $event)),
                    justify: "space-between"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("span", { class: "left-label" }, "Deals and New Product Emails", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              (_ctx.dealsAndProductEmails)
                ? (_openBlock(), _createBlock(_component_ion_radio_group, {
                    key: 0,
                    value: _ctx.selectedDeal,
                    mode: "md",
                    onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateSelectedDeal($event.detail.value)))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deals, (deal, i) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: deal,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_radio, {
                              disabled: !_ctx.dealsAndProductEmails,
                              value: i,
                              "label-placement": "end",
                              justify: "start"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_1, _toDisplayString(deal), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toggle, {
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.commPrefs.receivePersonalizedOffers,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.commPrefs.receivePersonalizedOffers) = $event)),
                    onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updatePrefs('receivePersonalizedOffers', _ctx.commPrefs.receivePersonalizedOffers)))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("span", { class: "left-label" }, "Reward Emails", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toggle, {
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.commPrefs.lrmEmails,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.commPrefs.lrmEmails) = $event)),
                    onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updatePrefs('lrmEmails', _ctx.commPrefs.lrmEmails)))
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("span", { class: "left-label" }, "Catering Promotions", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toggle, {
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.commPrefs.receiveSmsOffers,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.commPrefs.receiveSmsOffers) = $event)),
                    onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updatePrefs('receiveSmsOffers', _ctx.commPrefs.receiveSmsOffers)))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, [
                        _cache[11] || (_cache[11] = _createTextVNode("Text Messages ")),
                        (_ctx.account?.phoneNumber)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("to " + _toDisplayString(_ctx.account?.phoneNumber), 1)
                                ]),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
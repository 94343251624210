import ApiHelper from '@/services/ApiHelper';
import PageMeta from '@/models/PageMeta';
import StaticPageModel from '@/models/StaticPageModel';
import { htmlDecode } from './stringService';
import { toastController, ToastOptions } from '@ionic/vue';

function updateContent(elements: HTMLCollectionOf<HTMLMetaElement>, content?: string): void {
    if (content) {
        for (let i = 0; i < elements.length; i++) {
            elements[i].content = content;
        }
    }
}

export default function updateMeta(meta: PageMeta): void {
    const metaTitle = htmlDecode(meta.title ? `Donatos Pizza — ${meta.title}` : 'Donatos Pizza — Serving Pizza, Subs, Salads and Wings');
    document.title = metaTitle;
    const titleElements: HTMLCollectionOf<HTMLMetaElement> = document.getElementsByClassName('metaTitle') as HTMLCollectionOf<HTMLMetaElement>;
    const descriptionElements: HTMLCollectionOf<HTMLMetaElement> = document.getElementsByClassName('metaDescription') as HTMLCollectionOf<HTMLMetaElement>;
    const imageElements: HTMLCollectionOf<HTMLMetaElement> = document.getElementsByClassName('metaImage') as HTMLCollectionOf<HTMLMetaElement>;
    const urlElements: HTMLCollectionOf<HTMLMetaElement> = document.getElementsByClassName('metaUrl') as HTMLCollectionOf<HTMLMetaElement>;
    const robotEl = document.getElementById('robotIndex') as HTMLMetaElement;

    updateContent(titleElements, metaTitle);
    const metaDescription = meta.description
        ? meta.description
        : 'Order Edge to Edge®, abundantly topped pizza for delivery, pick up or eat in. Also featuring oven baked subs, fresh made salads, oven roasted wings and more. Order online, join Donatos Rewards…';
    updateContent(descriptionElements, metaDescription);
    if (meta.image) {
        updateContent(imageElements, meta.image?.includes('http') ? meta.image : window.location.origin + '/pictures/' + meta.image);
    }
    updateContent(urlElements, window.location.href);

    if (meta.twitterSummary) {
        const twitterSummaryEl = document.getElementById('twitterSummary') as HTMLMetaElement;
        twitterSummaryEl.content = meta.twitterSummary ?? metaDescription;
    }
    if (meta.publishTime) {
        const publishTimeEl = document.getElementById('publishTime') as HTMLMetaElement;
        publishTimeEl.content = new Date(meta.publishTime).toString();
    }

    const keywordsEl = document.getElementById('keywords') as HTMLMetaElement;
    keywordsEl.content = meta.keywords ? meta.keywords : 'Pizza, Subs, Salads, Wings, Dine in, Carryout, Delivery, Catering, Thin crust, Cauliflower crust, Gluten Free crust';

    if (process.env.NODE_ENV === 'production') {
        robotEl.content = meta.index ?? 'index';
    } else {
        robotEl.content = 'noindex';
    }
}

export const menuActionScroller = (weaken = 1) => {
    const actionScroll = document.getElementById('actionColScroll');
    setTimeout(() => {
        actionScroll?.scrollTo({
            top: actionScroll?.scrollHeight / weaken,
            behavior: 'smooth',
        });
    }, 100);
};
export const pageAccessedByReload =
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
        .getEntriesByType('navigation')
        //@ts-ignore
        .map((nav) => nav.type)
        .includes('reload');

export const errorToast = async (errorMessage: string, duration = 3000) => {
    await toastController
        .create({
            message: errorMessage,
            duration: duration,
            color: 'primary',
            position: 'top',
            cssClass: 'ion-text-center pa-4',
        })
        .then((t) => t.present());
};
export const successToast = async (successMessage: string) => {
    return await toastController
        .create({
            message: successMessage,
            duration: 4000,
            color: 'success',
            position: 'top',
            cssClass: 'ion-text-center  pa-4',
        })
        .then((t) => t.present());
};
export const successToastDetail = async (options: ToastOptions) => {
    return await toastController
        .create({
            message: options.message,
            duration: options.duration ?? 4000,
            color: options.color ?? 'primary',
            position: options.position,
            cssClass: 'ion-text-center ion-padding ' + options?.cssClass ?? '',
        })
        .then((t) => t.present());
};

export const vanillaQueryParam = (queryParam: string) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
    });
    return params[queryParam];
};

/** API */

export const fetchPage = async (uri: string): Promise<StaticPageModel[]> => ApiHelper.get(`/page?uri=${uri}`);

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebNavDrawer = _resolveComponent("WebNavDrawer")!
  const _component_UniversalHeader = _resolveComponent("UniversalHeader")!
  const _component_IonRouterOutlet = _resolveComponent("IonRouterOutlet")!
  const _component_TabNavigation = _resolveComponent("TabNavigation")!
  const _component_Stellar = _resolveComponent("Stellar")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (!_ctx.isNative)
        ? (_openBlock(), _createBlock(_component_WebNavDrawer, {
            key: 0,
            id: "mobile-nav-drawer"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_UniversalHeader),
      _createVNode(_component_IonRouterOutlet, {
        id: "main-content",
        class: "app page-padding"
      }),
      (_ctx.isNative)
        ? (_openBlock(), _createBlock(_component_TabNavigation, {
            key: 1,
            id: "mobile-nav-tabs"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Stellar)
    ]),
    _: 1
  }))
}
import { RouteRecordRaw } from 'vue-router';
import store from '@/store';
export enum StaticPageTypes {
    main = 'MAIN',
    legal = 'LEGAL',
}
const fetchStaticData = (to, from, next) => {
    store.dispatch('addStaticPageContent', to.path.replace(/\//, ''));
    next();
};

const mainStaticPageRoutes: Array<RouteRecordRaw> = [
    {
        path: '/givingback',
        name: 'Giving Back',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.main },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/company',
        name: 'Our Story',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.main },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/values',
        name: 'Values',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.main },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/news',
        name: 'News',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.main },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/fundraising',
        name: 'Fundraising',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.main },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/nutrition',
        name: 'Nutrition',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.main },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/franchise',
        name: 'Franchise',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.main },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/careers',
        name: 'Careers',
        component: () => import('@/views/careerPage.vue'),
        meta: {
            title: 'Careers',
            description: '',
        },
    },
    {
        path: '/customer-service',
        name: 'Customer Service',
        component: () => import('@/views/customerService/customerServicePage.vue'),
        meta: {
            title: 'Customer Service',
            description: '',
        },
    },
    {
        path: '/:catchAll(.*)',
        meta: { type: StaticPageTypes.main },
        component: () => import('@/views/staticPage.vue'),
        beforeEnter: fetchStaticData,
    },
];

const legalPageRoutes: Array<RouteRecordRaw> = [
    {
        path: '/termsandconditions',
        name: 'Terms & Conditions',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.legal },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.legal },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/copyright',
        name: 'Copyright',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.legal },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/rewardsterms',
        name: 'Rewards Terms',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.legal },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/smsterms',
        name: 'SMS Terms',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.legal },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/social-media-contest-terms',
        name: 'Social Media Contest Terms',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.legal },
        beforeEnter: fetchStaticData,
    },
    {
        path: '/rewardsfaq',
        name: 'Rewards FAQ',
        component: () => import('@/views/staticPage.vue'),
        meta: { type: StaticPageTypes.legal },
        beforeEnter: fetchStaticData,
    },
];

export default [...legalPageRoutes, ...mainStaticPageRoutes];

<template>
    <div>
        <div v-if="canDisplayBlock" v-html="selectedBlock.content" :id="slug"></div>
        <div v-if="!canDisplayBlock && backupText" :id="slug" v-html="backupText"></div>
        <slot v-if="!canDisplayBlock && backupSlot" />
    </div>
</template>

<script lang="ts">
import useContentBlock from '@/composables/useContentBlock';
export default {
    props: {
        slug: { type: String, required: true },
        backupText: String,
        backupSlot: Boolean,
    },
    setup(props) {
        const { selectedBlock, canDisplayBlock, contentBlocks } = useContentBlock(props.slug);
        return {
            contentBlocks,
            selectedBlock,
            canDisplayBlock,
        };
    },
};
</script>

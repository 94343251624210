import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NativeAppHeader = _resolveComponent("NativeAppHeader")!
  const _component_DesktopHeader = _resolveComponent("DesktopHeader")!
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, {
    role: "banner",
    "aria-label": "Navigational Container"
  }, {
    default: _withCtx(() => [
      (_ctx.isNative)
        ? (_openBlock(), _createBlock(_component_NativeAppHeader, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_DesktopHeader, { redirectToLocation: _ctx.redirectToLocation }, null, 8, ["redirectToLocation"]),
            _createVNode(_component_MobileHeader)
          ], 64))
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "no-address-msg",
  class: "ion-margin ion-text-center"
}
const _hoisted_2 = { class: "full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_addressDisplay = _resolveComponent("addressDisplay")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_addressForm = _resolveComponent("addressForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    ((!_ctx.addresses || _ctx.addresses.length == 0) && !_ctx.showForm)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _cache[3] || (_cache[3] = [
          _createTextVNode(" You have no addresses saved. "),
          _createElementVNode("br", null, null, -1),
          _createTextVNode(" Select Add Address to add an address and checkout even faster. ")
        ])))
      : _createCommentVNode("", true),
    (_ctx.addresses && _ctx.showList)
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 1,
          class: "py-0",
          "data-cy": "address-list"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_radio_group, {
              value: _ctx.selectedIndex,
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectAddress($event.detail.value))),
              mode: "md"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addresses, (address, i) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: address.addressId ?? i,
                    button: _ctx.selectable,
                    class: "address-item",
                    lines: "full"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.selectable)
                        ? (_openBlock(), _createBlock(_component_ion_radio, {
                            key: 0,
                            slot: "start",
                            value: i,
                            class: "address-radio",
                            "data-cy": 'radio-address-' + i
                          }, null, 8, ["value", "data-cy"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_row, { class: "full-width" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            (address.default)
                              ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createElementVNode("span", {
                                      "data-cy": "default",
                                      class: "small"
                                    }, "Default Address", -1)
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ion_row, { class: "my-4 ion-text-capitalize" }, {
                              default: _withCtx(() => [
                                _createElementVNode("strong", null, _toDisplayString(address.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_row, { class: "my-4" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, {
                                  size: "7",
                                  class: "pa-0"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_addressDisplay, {
                                      address: address.address,
                                      dense: ""
                                    }, null, 8, ["address"])
                                  ]),
                                  _: 2
                                }, 1024),
                                (_ctx.selectedIndex === i || !_ctx.selectable)
                                  ? (_openBlock(), _createBlock(_component_ion_col, {
                                      key: 0,
                                      size: "3",
                                      class: "ion-text-end"
                                    }, {
                                      default: _withCtx(() => [
                                        (_ctx.editable)
                                          ? (_openBlock(), _createBlock(_component_ion_button, {
                                              key: 0,
                                              small: "",
                                              style: {"width":"unset"},
                                              fill: "clear",
                                              color: "primary",
                                              class: "my-0 mr-4",
                                              "data-cy": "button-address-edit",
                                              onClick: ($event: any) => (_ctx.editAddress(address))
                                            }, {
                                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                                _createTextVNode(" Edit ")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.deleteable)
                                          ? (_openBlock(), _createBlock(_component_ion_button, {
                                              key: 1,
                                              small: "",
                                              style: {"width":"unset"},
                                              fill: "clear",
                                              color: "primary",
                                              class: "my-0",
                                              onClick: ($event: any) => (_ctx.deleteAddress(address)),
                                              "data-cy": "button-address-delete"
                                            }, {
                                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                _createTextVNode(" Remove ")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["button"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_row, { class: "mx-auto w-full pt-8" }, {
      default: _withCtx(() => [
        (_ctx.addBtn && _ctx.showList)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newAddress())),
              class: "mx-auto"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Add a New Address")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.showForm)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          fill: "clear",
          class: "back-button mx-auto",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleForm()))
        }, [
          _createVNode(_component_ion_icon, { icon: _ctx.arrowBackOutline }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showForm)
      ? (_openBlock(), _createBlock(_component_addressForm, {
          key: 3,
          "submit-function": _ctx.saveFunction,
          "initial-values": _ctx.formAddress,
          "submission-error": _ctx.submissionError,
          showname: "",
          "show-default-check": "",
          "submit-btn-text": "Save"
        }, null, 8, ["submit-function", "initial-values", "submission-error"]))
      : _createCommentVNode("", true)
  ]))
}
<template>
    <ion-input
        :label-placement="stacked ? 'stacked' : 'floating'"
        :fill="fill"
        placeholder="(555) 867-5308"
        inputmode="tel"
        v-model="localModelValue"
        v-on:keyup.enter="$emit('enter')"
        autocomplete="tel"
        pattern="tel"
        maxlength="14"
        color="medium"
        :error-text="error"
        class="custom"
        mode="md"
    >
        <div slot="label" class="label-color">Phone Number <ion-text v-if="required" color="danger">*</ion-text></div>
    </ion-input>
</template>

<script lang="ts">
import { IonInput, IonText } from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { formatPhoneNumber } from '@/services/stringService';
/**
 * For area code validation with "yup" add this to schema->
 * `.test("areaCode", "Phone number area code is not valid", (value) => isAreaCodeValid(value))
 */
export default defineComponent({
    props: {
        required: Boolean,
        modelValue: { type: String, default: '' },
        fill: String,
        labelColor: String,
        dense: Boolean,
        stacked: Boolean,
        mode: String,
        error: String,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const localModelValue = computed({
            get: () => formatPhoneNumber(props.modelValue),
            set: (newValue) => {
                const phone = newValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/) ?? [];
                emit('update:modelValue', phone[0]);
            },
        });
        return { localModelValue };
    },

    components: { IonInput, IonText },
});
</script>

<style scoped>
ion-input.custom {
    --placeholder-color: #92949c;
}
.label-color {
    color: #92949c;
}
</style>

import { ref } from 'vue';

export function useApiResponse() {
    const responseMessage = ref('');
    const responseModalVisible = ref(false);

    const showResponseModal = () => {
        responseModalVisible.value = true;
    };

    const hideResponseModal = () => {
        responseModalVisible.value = false;
    };

    return {
        responseMessage,
        responseModalVisible,
        showResponseModal,
        hideResponseModal,
    };
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "logged-in-header"
}
const _hoisted_2 = { class: "first-name" }
const _hoisted_3 = {
  key: 1,
  class: "nav-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_accountModal = _resolveComponent("accountModal")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    class: "nav-menu",
    "content-id": "main-content",
    type: "overlay",
    "data-cy": "mobile-nav-drawer"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        "aria-label": "mobile navigation",
        class: "ion-no-border mobile-nav-header",
        "data-cy": "mobile-nav-header"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { mode: "md" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, {
                size: "12",
                style: {"margin-bottom":"-35px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    class: "ion-align-self-end",
                    size: "1",
                    offset: "10"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_menu_toggle, { style: {"cursor":"pointer"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            style: {"font-size":"25px"},
                            ios: _ctx.close,
                            md: _ctx.close,
                            "data-cy": "mobile-nav-drawer-close"
                          }, null, 8, ["ios", "md"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, {
                class: "ion-align-items-center",
                size: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "8",
                    offset: "1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_menu_toggle, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, { to: "/" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_img, {
                                alt: "Donatos Logo",
                                class: "menu-logo",
                                src: require('@/assets/logo-dark.png'),
                                "data-cy": "mobile-nav-drawer-logo"
                              }, null, 8, ["src"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        "scroll-y": true,
        "force-overscroll": false
      }, {
        default: _withCtx(() => [
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_row, { size: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      class: "welcome",
                      size: "10",
                      offset: "1",
                      "data-cy": "welcome-back-message"
                    }, {
                      default: _withCtx(() => [
                        _cache[3] || (_cache[3] = _createTextVNode(" Welcome back, ")),
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.firstName), 1),
                        _cache[4] || (_cache[4] = _createTextVNode("! "))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_menu_toggle, null, {
                  default: _withCtx(() => [
                    (_ctx.account.cheetahMemberRewards)
                      ? (_openBlock(), _createBlock(_component_ion_row, {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = () => _ctx.router.replace('/rewards')),
                          size: "12"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_col, {
                              class: "account-rewards",
                              style: {"cursor":"pointer"},
                              size: "10",
                              offset: "1",
                              "data-cy": "check-rewards-cta"
                            }, {
                              default: _withCtx(() => [
                                _cache[5] || (_cache[5] = _createTextVNode(" Check your rewards ")),
                                _createVNode(_component_ion_icon, {
                                  style: {"vertical-align":"middle","font-size":"14px","margin-left":"-5px"},
                                  ios: _ctx.chevronForward,
                                  md: _ctx.chevronForward,
                                  color: "primary"
                                }, null, 8, ["ios", "md"]),
                                _createVNode(_component_ion_icon, {
                                  style: {"vertical-align":"middle","font-size":"14px","margin-left":"-9px"},
                                  ios: _ctx.chevronForward,
                                  md: _ctx.chevronForward,
                                  color: "primary"
                                }, null, 8, ["ios", "md"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "list-divider" }, null, -1))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_menu_toggle, { class: "nav-list" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (page, i) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: i,
                  to: { path: page.url, query: { utm_medium: 'web', utm_source: 'nav' } }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      lines: "none",
                      button: "",
                      "data-cy": "mobile-nav-drawer-links",
                      id: page.title + '-link'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "nav-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(page.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["id"])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ]),
            _: 1
          }),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "list-divider" }, null, -1)),
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_accountModal),
                _createVNode(_component_ion_item, {
                  onClick: _ctx.logout,
                  lines: "none",
                  detail: "false",
                  button: "",
                  "data-cy": "mobile-nav-drawer-user-links"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "nav-label" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Sign Out ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (!_ctx.isLoggedIn)
        ? (_openBlock(), _createBlock(_component_ion_row, {
            key: 0,
            size: "12",
            class: "rewards-container",
            "data-cy": "rewards-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                size: "12",
                class: "rewards-col"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    alt: "rewards Image",
                    src: require('@/assets/donatos-rewards-logo-dark.svg'),
                    class: "rewards-logo"
                  }, null, 8, ["src"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                size: "12",
                class: "rewards-text",
                "data-cy": "rewards-message"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("span", null, "Points, Perks and Free Pizza!", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                size: "10",
                offset: "1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_toggle, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "join-now",
                        onClick: _cache[1] || (_cache[1] = () => _ctx.router.push('/signup')),
                        expand: "block",
                        "data-cy": "button-join-now"
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Join Now")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                size: "10",
                offset: "1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_toggle, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[2] || (_cache[2] = () => _ctx.router.replace('/login')),
                        expand: "block",
                        fill: "outline",
                        "data-cy": "button-sign-in"
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("Sign In")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
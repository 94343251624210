<template>
    <ion-button fill="clear" class="py-0 px-0" @click="btnClick">
        <ion-icon :ios="cart" :md="cart" :size="iconSize" :color="iconColor" :style="iconStyle" />
        <ion-badge id="cart-badge" color="primary" mode="ios" style="margin-right: 2px" v-if="cartCount > 0" class="px-8">{{ cartCount }}</ion-badge>
    </ion-button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { IonIcon, IonBadge, IonButton } from '@ionic/vue';
import { cart } from 'ionicons/icons';
import { useStore } from 'vuex';

export default defineComponent({
    props: {
        size: String,
        color: String,
        style: Object,
    },
    setup(props) {
        const store = useStore();
        const cartCount = computed(() => store.getters.getCartQuantity);
        let iconSize = props.size || '';
        let iconColor = props.color || '';
        let iconStyle = props.style || '';

        const btnClick = () => {
            //@ts-ignore
            top.location.href = '/cart';
        };

        return { cart, iconSize, iconColor, iconStyle, cartCount, btnClick };
    },
    components: {
        IonIcon,
        IonBadge,
        IonButton,
    },
});
</script>

<style scoped>
#cart-badge {
    position: absolute;
    top: 0px;
    right: -6px;
    font-size: 9px;
    padding: 2.5px;
    width: 14px;
    height: 14px;
    letter-spacing: -1.1px;
}
</style>

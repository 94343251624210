import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_creditCardModal = _resolveComponent("creditCardModal")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_credit_card_item = _resolveComponent("credit-card-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_creditCardModal, {
      modalOpen: _ctx.creditCardModalOpen,
      hideCheckBox: true,
      onModalOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.creditCardModalOpen = $event)),
      onAddCreditCard: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveCard($event)))
    }, null, 8, ["modalOpen"]),
    _createVNode(_component_ion_grid, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                (!_ctx.cards || _ctx.cards.length == 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_1, "You have no cards saved. Select Add Card to add a card and checkout even faster."))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
          return (_openBlock(), _createBlock(_component_ion_row, {
            key: card.id,
            class: "list-item"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_credit_card_item, {
                card: card,
                onHandleDelete: _ctx.deleteCard,
                onHandleMakePrimary: _ctx.makePrimaryCard
              }, null, 8, ["card", "onHandleDelete", "onHandleMakePrimary"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, { class: "addPaymentColumn" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  class: "icon-button",
                  onClick: _ctx.openCreditCardModal,
                  id: "add-payment-button"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      ios: _ctx.addCircleOutline,
                      md: _ctx.addCircleOutline
                    }, null, 8, ["ios", "md"]),
                    _cache[2] || (_cache[2] = _createTextVNode("  Add Payment "))
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
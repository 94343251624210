<template>
    <ion-input
        v-bind="$attrs"
        :label-placement="stacked ? 'stacked' : 'floating'"
        :fill="fill"
        v-model="localModelValue"
        inputmode="numeric"
        type="number"
        :autocomplete="autoComplete ? 'postal-code' : false"
        data-cy="input-zip"
        v-on:keup.enter="$emit('enter')"
        :tabindex="tabindex"
        name="zip"
        class="custom"
        mode="md"
    >
        <div slot="label" class="label-color" v-if="label">{{ label }} <ion-text v-if="required" color="danger">*</ion-text></div>
        <div slot="end">
            <ion-button v-if="searchBtn" slot="end" fill="clear" item-right @click="$emit('enter')" color="medium">
                <ion-spinner v-if="searching" name="lines-small" />

                <ion-icon v-else :icon="searchSharp" color="medium" />
            </ion-button>
        </div>
    </ion-input>
    <ion-note color="danger" data-cy="error-zip">{{ error }}</ion-note>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { IonInput, IonText, IonNote, IonButton, IonIcon, IonSpinner } from '@ionic/vue';

import { searchSharp } from 'ionicons/icons';

export default defineComponent({
    props: {
        required: Boolean,
        tabindex: { type: String, default: '' },
        label: String,
        autoComplete: Boolean,
        searchBtn: Boolean,
        searching: Boolean,
        fill: String,
        stacked: Boolean,
        error: { type: String || undefined },
        modelValue: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue', 'enter', 'blur'],
    setup(props, { emit }) {
        const localModelValue = computed({
            get: () => props.modelValue,
            set: (newValue) => emit('update:modelValue', newValue),
        });

        return { localModelValue, searchSharp };
    },
    components: {
        IonInput,
        IonText,
        IonNote,
        IonButton,
        IonIcon,
        IonSpinner,
    },
});
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

ion-input.custom {
    --placeholder-color: #92949c;
}
.label-color {
    color: #92949c;
}
</style>

<template>
    <ion-header role="navigation" aria-label="mobile navigation header" class="ion-no-border">
        <ion-toolbar mode="md" id="mobile-web-navigation">
            <ion-buttons slot="start" id="mobile-nav-hamburger" data-cy="mobile-nav-hamburger">
                <ion-menu-button color="medium" />
            </ion-buttons>

            <ion-buttons slot="end" style="padding: 10px 10px">
                <cartBtn size="large" color="medium" />
            </ion-buttons>

            <ion-title class="ion-text-center">
                <router-link to="/" aria-label="home">
                    <ion-img alt="Donatos Logo" class="menu-logo" :src="require('@/assets/logo-dark.png')" />
                </router-link>
            </ion-title>
        </ion-toolbar>
    </ion-header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CartBtn from '@/components/cartBtn.vue';
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonTitle, IonImg } from '@ionic/vue';

export default defineComponent({
    components: { IonHeader, IonToolbar, IonMenuButton, IonButtons, CartBtn, IonTitle, IonImg },
});
</script>

<style scoped>
.menu-logo {
    max-width: 150px;
    margin: 0 auto;
}
@media only screen and (min-width: 960px) {
    #mobile-nav-drawer,
    #mobile-web-navigation {
        display: none;
    }
}
</style>

import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mt-0 mb-4" }
const _hoisted_2 = { class: "mt-0 mb-4 pl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_inlineList = _resolveComponent("inlineList")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_contentBlock = _resolveComponent("contentBlock")!

  return (_openBlock(), _createBlock(_component_ion_row, null, {
    default: _withCtx(() => [
      (!_ctx.history || typeof _ctx.history === 'string')
        ? (_openBlock(), _createBlock(_component_ion_col, {
            key: 0,
            class: "ion-text-center"
          }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "It looks like you have not ordered anything yet.. Let's change that!", -1)),
              _createVNode(_component_ion_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('close'))),
                "router-link": "/menu"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Start my order")
                ])),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.history, (order) => {
            return (_openBlock(), _createBlock(_component_ion_item, {
              key: order.order,
              class: "full-width",
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "full-width ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                      default: _withCtx(() => [
                        (_ctx.isMobile)
                          ? (_openBlock(), _createBlock(_component_ion_col, {
                              key: 0,
                              class: "py-0",
                              size: _ctx.addOrderBtn ? 8 : 12,
                              "size-md": _ctx.addOrderBtn ? 9 : 12
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_1, _toDisplayString(order.displayDate), 1),
                                _createVNode(_component_inlineList, {
                                  items: order.items,
                                  keyName: "itemName",
                                  "data-cy": "order-line-items",
                                  class: "my-0 normal ion-text-wrap",
                                  displayFunc: _ctx.inlineOrderHistory
                                }, null, 8, ["items", "displayFunc"])
                              ]),
                              _: 2
                            }, 1032, ["size", "size-md"]))
                          : _createCommentVNode("", true),
                        (!_ctx.isMobile)
                          ? (_openBlock(), _createBlock(_component_ion_col, {
                              key: 1,
                              class: "py-0"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_2, _toDisplayString(order.displayDate), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (!_ctx.isMobile)
                          ? (_openBlock(), _createBlock(_component_ion_col, {
                              key: 2,
                              class: "py-0 text-center"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_inlineList, {
                                  items: order.items,
                                  keyName: "itemName",
                                  "data-cy": "order-line-items",
                                  class: "my-0 normal ion-text-wrap font-18",
                                  displayFunc: _ctx.inlineOrderHistory
                                }, null, 8, ["items", "displayFunc"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (_ctx.addOrderBtn)
                          ? (_openBlock(), _createBlock(_component_ion_col, {
                              key: 3,
                              class: "py-0 ion-text-end"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  fill: "clear",
                                  onClick: ($event: any) => (_ctx.addToCart(order))
                                }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("Add to cart")
                                  ])),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128)),
      _createVNode(_component_ion_col, {
        size: "12",
        class: "ion-text-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_contentBlock, { slug: "order-history-100" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
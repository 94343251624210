import { ElavonResponse } from '@/models/PaymentModels';
import { ref } from 'vue';
import { fetchPaymentGatewayToken } from '@/services/accountService';

export default function useCheckoutJS() {
    const paymentGatewaySuccess = ref(new ElavonResponse({}));
    const paymentGatewayError = ref('');

    const loadElavonScript = () => {
        const elavonScript = document.createElement('script');
        elavonScript.setAttribute('src', process.env.VUE_APP_CHECKOUT_JS_URL);
        document.head.appendChild(elavonScript);
    };

    const handleError = (error) => {
        console.log('Gateway Error: ', error);
        paymentGatewayError.value = error.errorName;
        paymentGatewaySuccess.value = new ElavonResponse({});
    };

    const handleSuccess = (response) => {
        paymentGatewayError.value = '';
        paymentGatewaySuccess.value = new ElavonResponse(response);
    };

    const callback = {
        onError: function (error) {
            handleError(error);
        },
        onDeclined: function (response) {
            handleError(response);
        },
        onApproval: function (response) {
            handleSuccess(response);
        },
    };

    const getTokenizedPayment = (paymentFields, recaptcha) => {
        fetchPaymentGatewayToken(recaptcha)
            .then((authToken) => {
                const paymentData = {
                    ssl_txn_auth_token: authToken,
                    ssl_card_number: paymentFields.cardNumber,
                    ssl_exp_date: paymentFields.expirationDate,
                    ssl_get_token: 'Y',
                    ssl_add_token: 'N',
                    ssl_first_name: paymentFields.firstName ?? '',
                    ssl_last_name: paymentFields.lastName ?? '',
                    ssl_cvv2cvc2: paymentFields.cvv,
                    ssl_avs_zip: paymentFields.zipCode,
                    ssl_merchant_txn_id: '0024335',
                };
                // imported from loadElavonScript
                //@ts-ignore
                ConvergeEmbeddedPayment.pay(paymentData, callback);
                return false;
            })
            .catch((error) => {
                handleError(`Error Authenticating Payment Gateway: , ${error}`);
            });
    };

    return { getTokenizedPayment, loadElavonScript, paymentGatewayError, paymentGatewaySuccess };
}

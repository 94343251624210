import { createStore } from 'vuex';

import { FullOrder, OrderDetail, OrderLogistics } from '@/models/cartModels';

import selectedLocationModule from '@/store/selectedLocationModule';
import locationSearchModule from '@/store/locationSearchModule';
import accountModule from '@/store/accountModule';
import pageModule from '@/store/pageModule';
import orderModule from '@/store/orderModule';
import contentBlockModule from '@/store/contentBlockModule';
import bundleModule from '@/store/bundleModule';
import notificationModule from '@/store/notificationModule';
import { getLocalStorage, localStorageEnum } from '@/services/localStorageService';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        selectedLocationModule,
        locationSearchModule,
        accountModule,
        pageModule,
        orderModule,
        contentBlockModule,
        bundleModule,
        notificationModule,
    },
    getters: {
        getOrderLogistics: (state: any, getters: any): OrderLogistics => {
            //@ts-ignore
            return new OrderLogistics({
                date: getters.getOrderDate,
                time: getters.getOrderTime,
                isImmediate: getters.getIsImmediate.toString(),
                customerId: getters.getAccountId,
                phoneNumber: getters.getAccount?.asOrderHeader?.phoneNumber,
                storeNumber: getters.getSelectedLocation?.id,
                orderType: getters.getCurrentOrderType,
                deliveryInfo: getters.getDeliveryInfo,
                pickupInfo: getters.getPickupInfo,
            });
        },
        getOrderDetail: (state: any, getters: any): OrderDetail => {
            const totals = getters.getOrderTotals;

            return new OrderDetail({
                orderLogistics: getters.getOrderLogistics,
                coupons: getters.getValidCoupons,
                webSpecials: [],
                payments: getters.getPayments,
                lineItems: getters.getCartItems,
                subTotal: totals?.cartTotal,
                taxTotal: totals?.tax,
                grandTotal: totals.grandTotal,
            });
        },
        getFullOrder: (state: any, getters: any): FullOrder =>
            //@ts-ignore
            new FullOrder({
                timezoneOffset: getters.getSelectedLocation?.offsetFromBrowser,
                orderHeader: getters.getAccountOrGuest?.asOrderHeader,
                orderDetails: getters.getOrderDetail,
            }),
    },
});

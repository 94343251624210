<template>
    <creditCardModal :modalOpen="creditCardModalOpen" :hideCheckBox="true" @modalOpen="creditCardModalOpen = $event" @addCreditCard="saveCard($event)" />

    <ion-grid>
        <ion-row>
            <ion-col>
                <p v-if="!cards || cards.length == 0">You have no cards saved. Select Add Card to add a card and checkout even faster.</p>
            </ion-col>
        </ion-row>
        <ion-row v-for="card in cards" :key="card.id" class="list-item">
            <credit-card-item :card="card" @handleDelete="deleteCard" @handleMakePrimary="makePrimaryCard"></credit-card-item>
        </ion-row>

        <ion-row>
            <ion-col class="addPaymentColumn">
                <ion-button class="icon-button" @click="openCreditCardModal" id="add-payment-button"> <ion-icon :ios="addCircleOutline" :md="addCircleOutline" />&nbsp; Add Payment </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts">
import { IonButton, IonIcon, IonRow, IonCol, IonGrid } from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';
import { trashOutline, addCircleOutline } from 'ionicons/icons';
import { CreditCard } from '@/models/PaymentModels';
import CreditCardItem from './creditCardItem.vue';
import { Account } from '@/models/account/authModels';
import { useStore } from 'vuex';
import CreditCardModal from './checkout/creditCardModal.vue';
import useCheckoutJS from '@/composables/useCheckoutJS';
import { errorToast } from '@/services/pageService';

export default defineComponent({
    props: { account: Account },
    setup(props) {
        // Payment Gateway
        const { loadElavonScript } = useCheckoutJS();

        const store = useStore();
        const customerId = computed(() => props.account?.customerId ?? '');
        const cards = computed(() => props.account?.savedCards);
        const creditCardModalOpen = ref(false);

        const openCreditCardModal = () => {
            loadElavonScript();
            creditCardModalOpen.value = true;
        };

        const saveCard = async ({ creditCard }) => {
            try {
                creditCard.customerId = customerId.value;
                await store.dispatch('addSavedCard', creditCard);
            } catch (err) {
                if (err === 'Card Updated') {
                    errorToast(`This card has added already!`, 5000);
                }
            }
        };

        const makePrimaryCard = (card: CreditCard) => {
            //@ts-ignore
            const defaultCard = cards.value?.find((card) => card.isDefaultCard);
            if (defaultCard) {
                let currentDefaultCard: CreditCard = new CreditCard(defaultCard);
                currentDefaultCard.isDefaultCard = false;
                store.dispatch('updateSavedCard', currentDefaultCard);
            }
            card.isDefaultCard = true;
            store.dispatch('updateSavedCard', card);
        };

        const deleteCard = (card) => {
            store.dispatch('deleteSavedCard', card);
        };

        return { cards, trashOutline, addCircleOutline, makePrimaryCard, deleteCard, creditCardModalOpen, openCreditCardModal, saveCard };
    },

    components: {
        IonIcon,
        IonButton,
        IonRow,
        IonCol,
        IonGrid,
        CreditCardItem,
        CreditCardModal,
    },
});
</script>

<style scoped>
.text-right {
    text-align: right;
}

ion-col {
    margin-top: 20px;
    margin-bottom: 20px;
}

ion-button.icon-button {
    --background: transparent;
    --box-shadow: none;
    --color: gray;
    text-transform: none;
}

ion-button#add-payment-button {
    color: var(--ion-color-primary);
    font-weight: 600;
    font-size: large;
}

ion-chip.primary {
    --color: white;
    --background: var(--ion-color-medium-shade);
}

ion-chip.secondary {
    --color: var(--ion-color-dark-shade);
}

.addPaymentColumn {
    text-align: center;
}
</style>

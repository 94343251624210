import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  slot: "label",
  class: "label-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_input, {
    "label-placement": "floating",
    fill: _ctx.fill,
    placeholder: _ctx.placeholder,
    modelValue: _ctx.localModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
    type: _ctx.type,
    autocomplete: _ctx.autocomplete,
    color: "medium",
    class: "custom",
    mode: "md"
  }, {
    default: _withCtx(() => [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.required)
              ? (_openBlock(), _createBlock(_component_ion_text, {
                  key: 0,
                  color: "danger"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("*")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["fill", "placeholder", "modelValue", "type", "autocomplete"]))
}